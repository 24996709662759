import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import axios from 'axios';
import getCSRFToken from '../../csrf';
import { includes } from "lodash";

export interface ListingStayMapPageProps {
  className?: string;
}

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.csrf_token_status = "unavailable";

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  const [gyms, setGyms] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCSRFToken(); // Her seferinde token'ı yenileyin
        const gymId = searchParams.get('gymId');
        const locationId = searchParams.get('locationId');
        const cityId = searchParams.get('cityId');

        let requestData: { [key: string]: string } = {};

        if (gymId) {
          requestData = { gym_id: gymId };
        } else if (locationId) {
          requestData = { location_id: locationId };
        } else if (cityId) {
          requestData = { city_id: cityId };
        } else {
          requestData = { location_id: "1" }; // Varsayılan değer
        }


        await fetchGyms(requestData);
      } catch (error) {
        console.error('Veri çekme hatası:', error);
      }
    };

    fetchData();
  }, [searchParams]);

  const fetchGyms = async (requestData: { [key: string]: string }) => {
    try {
      const csrfToken = localStorage.getItem('csrf_token');
      if (!csrfToken) {
        throw new Error('CSRF token bulunamadı');
      }
      console.log('Gönderilen CSRF Token:', csrfToken); // Token'ı kontrol edin

      const response = await axios.post('https://api.sporharitam.com/get/gyms', 
        {
          ...requestData
        },
        {
          withCredentials: true,
          headers: {
            'X-CSRF-Token': csrfToken // Header'a da ekleyin
          }
        }
      );

      if (response.data.status === 'success') {
        setGyms(response.data.data);
      } else {
        console.error('API Hatası:', response.data);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data || error.message);
      } else {
        console.error('Fetch error:', error);
      }
    }
  };
  
  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Chisfis || Booking React Template</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
        <SectionHeroArchivePage currentPage="Stays" currentTab="Stays" />
      </div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap gyms={gyms} />
      </div>

      <div className="container overflow-hidden">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />

        {/* SECTION */}
        <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>
      </div>
    </div>
  );
};

export default ListingStayMapPage;
