import React, { FC, ReactNode, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  gyms?: any[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Popüler spor salonları",
  subHeading = "Sizin için önerilen spor salonlarını keşfedin",
  headingIsCenter,
  tabs = ["İzmir", "İstanbul", "Ankara"],
}) => {
  const [gyms, setGyms] = useState<any[]>([]);

  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  const fetchGyms = async (locationName: string) => {
    try {
      const response = await fetch('https://api.sporharitam.com/get/gyms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
        credentials: 'include', // Bu, çerezlerin gönderilmesini sağlar
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      if (data.status === 'success') {
        // Spor salonlarını işleyin
        setGyms(data.data);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"İzmir"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(e) => {console.log(e)}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {DEMO_DATA.map((stay) => renderCard(stay))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Daha fazla göster</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
