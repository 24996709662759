import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";



const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/#" element={<PageHome />} />
        <Route path="/home-1-header-2" element={<PageHome />} />
        <Route path="/home-2" element={<PageHome2 />} />
        <Route path="/home-3" element={<PageHome3 />} />
        
        <Route path="/listing-stay" element={<ListingStayPage />} />
        <Route path="/listing-stay-map" element={<ListingStayMapPage />} />
        <Route path="/listing-stay-detail/:id" element={<ListingStayDetailPage />} />
        
        <Route path="/listing-experiences" element={<ListingExperiencesPage />} />
        <Route path="/listing-experiences-map" element={<ListingExperiencesMapPage />} />
        <Route path="/listing-experiences-detail" element={<ListingExperiencesDetailPage />} />
        
        <Route path="/listing-car" element={<ListingCarPage />} />
        <Route path="/listing-car-map" element={<ListingCarMapPage />} />
        <Route path="/listing-car-detail" element={<ListingCarDetailPage />} />
        
        <Route path="/listing-real-estate-map" element={<ListingRealEstateMapPage />} />
        <Route path="/listing-real-estate" element={<ListingRealEstatePage />} />
        
        <Route path="/listing-flights" element={<ListingFlightsPage />} />
        
        <Route path="/checkout" element={<CheckOutPage />} />
        <Route path="/pay-done" element={<PayPage />} />
        
        <Route path="/author" element={<AuthorPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/account-password" element={<AccountPass />} />
        <Route path="/account-savelists" element={<AccountSavelists />} />
        <Route path="/account-billing" element={<AccountBilling />} />
        
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-single" element={<BlogSingle />} />
        
        <Route path="/add-listing-1" element={<PageAddListing1 />} />
        <Route path="/add-listing-2" element={<PageAddListing2 />} />
        <Route path="/add-listing-3" element={<PageAddListing3 />} />
        <Route path="/add-listing-4" element={<PageAddListing4 />} />
        <Route path="/add-listing-5" element={<PageAddListing5 />} />
        <Route path="/add-listing-6" element={<PageAddListing6 />} />
        <Route path="/add-listing-7" element={<PageAddListing7 />} />
        <Route path="/add-listing-8" element={<PageAddListing8 />} />
        <Route path="/add-listing-9" element={<PageAddListing9 />} />
        <Route path="/add-listing-10" element={<PageAddListing10 />} />
        
        <Route path="/contact" element={<PageContact />} />
        <Route path="/about" element={<PageAbout />} />
        <Route path="/signup" element={<PageSignUp />} />
        <Route path="/login" element={<PageLogin />} />
        <Route path="/subscription" element={<PageSubcription />} />
        
        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
