import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Neden sporharitam?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Spora sporharitamla başla! Sizin için en iyi spor salonunu bulmayı kolaylaştırıyor, en iyi olanaklara uygun fiyatlara ulaşabilmenizi vaad ediyoruz. Güç kazanmak, kondisyonunuzu arttırmak ve sağlıklı yaşama adım atmanız için zahmetsiz ve hızlı bir başlangıç sunuyoruz.
        </span>
        <ButtonPrimary className="mt-6 sm:mt-11">
          Hemen başla
        </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
