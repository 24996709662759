import axios from "axios";

export default function getCSRFToken() {
  return new Promise((resolve, reject) => {
    if (window.csrf_token_status === "available") return resolve();
    if (window.csrf_token_status === "requested") {
        const interval = setInterval(function(){
           if (window.csrf_token_status === "available") {
               clearInterval(interval);
               return resolve();
           }
        }, 500);
    } else {
       const server_url = process.env.REACT_APP_SITE_URL;
       if (!server_url) {
         return reject(new Error("SITE_URL is not defined in environment variables"));
       }
       window.csrf_token_status = "requested";
       
       axios
       .get(server_url+'?method=getCSRFToken')
       .then((response) => {
          if (typeof response.data === 'object' && response.data !== null && response.data.data && response.data.data.csrf_token) {
            window.localStorage.setItem('csrf_token', response.data.data.csrf_token);
            console.log('CSRF Token alındı:', response.data.data.csrf_token);
          } else {
            console.error('Geçersiz CSRF token yanıtı:', response.data);
            return reject(new Error('Geçersiz CSRF token yanıtı'));
          }
          window.csrf_token_status = "available";
          return resolve();
       })
       .catch((error) => {
          window.csrf_token_status = "unavailable";
          return reject(error);
       });
    }
  });
};
