const data = {
    locations3: [
        {"id":"1","bolge":"AKDENİZ","il":"ADANA","ilce":"ALADAĞ","plaka":"1","nviid":"1757"},
        {"id":"2","bolge":"AKDENİZ","il":"ADANA","ilce":"CEYHAN","plaka":"1","nviid":"1219"},
        {"id":"3","bolge":"AKDENİZ","il":"ADANA","ilce":"ÇUKUROVA","plaka":"1","nviid":"2033"},
        {"id":"4","bolge":"AKDENİZ","il":"ADANA","ilce":"FEKE","plaka":"1","nviid":"1329"},
        {"id":"5","bolge":"AKDENİZ","il":"ADANA","ilce":"İMAMOĞLU","plaka":"1","nviid":"1806"},
        {"id":"6","bolge":"AKDENİZ","il":"ADANA","ilce":"KARAİSALI","plaka":"1","nviid":"1437"},
        {"id":"7","bolge":"AKDENİZ","il":"ADANA","ilce":"KARATAŞ","plaka":"1","nviid":"1443"},
        {"id":"8","bolge":"AKDENİZ","il":"ADANA","ilce":"KOZAN","plaka":"1","nviid":"1486"},
        {"id":"9","bolge":"AKDENİZ","il":"ADANA","ilce":"POZANTI","plaka":"1","nviid":"1580"},
        {"id":"10","bolge":"AKDENİZ","il":"ADANA","ilce":"SAİMBEYLİ","plaka":"1","nviid":"1588"},
        {"id":"11","bolge":"AKDENİZ","il":"ADANA","ilce":"SARIÇAM","plaka":"1","nviid":"2032"},
        {"id":"12","bolge":"AKDENİZ","il":"ADANA","ilce":"SEYHAN","plaka":"1","nviid":"1104"},
        {"id":"13","bolge":"AKDENİZ","il":"ADANA","ilce":"TUFANBEYLİ","plaka":"1","nviid":"1687"},
        {"id":"14","bolge":"AKDENİZ","il":"ADANA","ilce":"YUMURTALIK","plaka":"1","nviid":"1734"},
        {"id":"15","bolge":"AKDENİZ","il":"ADANA","ilce":"YÜREĞİR","plaka":"1","nviid":"1748"},
        {"id":"16","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"MERKEZ","plaka":"2","nviid":"1105"},
        {"id":"17","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"ÇELİKHAN","plaka":"2","nviid":"1246"},
        {"id":"18","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"GERGER","plaka":"2","nviid":"1347"},
        {"id":"19","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"GÖLBAŞI","plaka":"2","nviid":"1354"},
        {"id":"20","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"KAHTA","plaka":"2","nviid":"1425"},
        {"id":"21","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"SAMSAT","plaka":"2","nviid":"1592"},
        {"id":"22","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"SİNCİK","plaka":"2","nviid":"1985"},
        {"id":"23","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"TUT","plaka":"2","nviid":"1989"},
        {"id":"24","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"MERKEZ","plaka":"3","nviid":"1108"},
        {"id":"25","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"BAYAT","plaka":"3","nviid":"1773"},
        {"id":"26","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"BOLVADİN","plaka":"3","nviid":"1200"},
        {"id":"27","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"ÇAY","plaka":"3","nviid":"1239"},
        {"id":"28","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"ÇOBANLAR","plaka":"3","nviid":"1906"},
        {"id":"29","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"DAZKIRI","plaka":"3","nviid":"1267"},
        {"id":"30","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"DİNAR","plaka":"3","nviid":"1281"},
        {"id":"31","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"EMİRDAĞ","plaka":"3","nviid":"1306"},
        {"id":"32","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"EVCİLER","plaka":"3","nviid":"1923"},
        {"id":"33","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"HOCALAR","plaka":"3","nviid":"1944"},
        {"id":"34","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"İHSANİYE","plaka":"3","nviid":"1404"},
        {"id":"35","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"İSCEHİSAR","plaka":"3","nviid":"1809"},
        {"id":"36","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"KIZILÖREN","plaka":"3","nviid":"1961"},
        {"id":"37","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"SANDIKLI","plaka":"3","nviid":"1594"},
        {"id":"38","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"SİNANPAŞA","plaka":"3","nviid":"1626"},
        {"id":"39","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"SULTANDAĞI","plaka":"3","nviid":"1639"},
        {"id":"40","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"ŞUHUT","plaka":"3","nviid":"1664"},
        {"id":"41","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"DİYADİN","plaka":"4","nviid":"1283"},
        {"id":"42","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"DOĞUBAYAZIT","plaka":"4","nviid":"1287"},
        {"id":"43","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"ELEŞKİRT","plaka":"4","nviid":"1301"},
        {"id":"44","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"HAMUR","plaka":"4","nviid":"1379"},
        {"id":"45","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"PATNOS","plaka":"4","nviid":"1568"},
        {"id":"46","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"TAŞLIÇAY","plaka":"4","nviid":"1667"},
        {"id":"47","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"TUTA","plaka":"4","nviid":"47"},
        {"id":"48","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"MERKEZ","plaka":"68","nviid":"1120"},
        {"id":"49","bolge":"KARADENİZ","il":"AMASYA","ilce":"GÖYNÜCEK","plaka":"5","nviid":"1363"},
        {"id":"50","bolge":"KARADENİZ","il":"AMASYA","ilce":"GÜMÜŞHACIKÖY","plaka":"5","nviid":"1368"},
        {"id":"51","bolge":"KARADENİZ","il":"AMASYA","ilce":"HAMAMÖZÜ","plaka":"5","nviid":"1938"},
        {"id":"52","bolge":"KARADENİZ","il":"AMASYA","ilce":"MERKEZ","plaka":"5","nviid":"1134"},
        {"id":"53","bolge":"KARADENİZ","il":"AMASYA","ilce":"MERZİFON","plaka":"5","nviid":"1524"},
        {"id":"54","bolge":"KARADENİZ","il":"AMASYA","ilce":"SULUOVA","plaka":"5","nviid":"1641"},
        {"id":"55","bolge":"KARADENİZ","il":"AMASYA","ilce":"TAŞOVA","plaka":"5","nviid":"1668"},
        {"id":"56","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"AKYURT","plaka":"6","nviid":"1872"},
        {"id":"57","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ALTINDAĞ","plaka":"6","nviid":"1130"},
        {"id":"58","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"AYAŞ","plaka":"6","nviid":"1157"},
        {"id":"59","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"BALA","plaka":"6","nviid":"1167"},
        {"id":"60","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"BEYPAZARI","plaka":"6","nviid":"1187"},
        {"id":"61","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ÇAMLIDERE","plaka":"6","nviid":"1227"},
        {"id":"62","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ÇANKAYA","plaka":"6","nviid":"1231"},
        {"id":"63","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ÇUBUK","plaka":"6","nviid":"1260"},
        {"id":"64","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ELMADAĞ","plaka":"6","nviid":"1302"},
        {"id":"65","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ETİMESGUT","plaka":"6","nviid":"1922"},
        {"id":"66","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"EVREN","plaka":"6","nviid":"1924"},
        {"id":"67","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"GÖLBAŞI","plaka":"6","nviid":"1744"},
        {"id":"68","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"GÜDÜL","plaka":"6","nviid":"1365"},
        {"id":"69","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"HAYMANA","plaka":"6","nviid":"1387"},
        {"id":"70","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"KALECİK","plaka":"6","nviid":"1427"},
        {"id":"71","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"KAZAN","plaka":"6","nviid":"1815"},
        {"id":"72","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"KEÇİÖREN","plaka":"6","nviid":"1745"},
        {"id":"73","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"KIZILCAHAMAM","plaka":"6","nviid":"1473"},
        {"id":"74","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"MAMAK","plaka":"6","nviid":"1746"},
        {"id":"75","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"NALLIHAN","plaka":"6","nviid":"1539"},
        {"id":"76","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"POLATLI","plaka":"6","nviid":"1578"},
        {"id":"77","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"PURSAKLAR","plaka":"6","nviid":"2034"},
        {"id":"78","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"SİNCAN","plaka":"6","nviid":"1747"},
        {"id":"79","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"ŞEREFLİKOÇHİSAR","plaka":"6","nviid":"1658"},
        {"id":"80","bolge":"İÇ ANADOLU","il":"ANKARA","ilce":"YENİMAHALLE","plaka":"6","nviid":"1723"},
        {"id":"81","bolge":"AKDENİZ","il":"ANTALYA","ilce":"AKSEKİ","plaka":"7","nviid":"1121"},
        {"id":"82","bolge":"AKDENİZ","il":"ANTALYA","ilce":"AKSU","plaka":"7","nviid":"2035"},
        {"id":"83","bolge":"AKDENİZ","il":"ANTALYA","ilce":"ALANYA","plaka":"7","nviid":"1126"},
        {"id":"84","bolge":"AKDENİZ","il":"ANTALYA","ilce":"DEMRE","plaka":"7","nviid":"1811"},
        {"id":"85","bolge":"AKDENİZ","il":"ANTALYA","ilce":"DÖŞEMEALTI","plaka":"7","nviid":"2036"},
        {"id":"86","bolge":"AKDENİZ","il":"ANTALYA","ilce":"ELMALI","plaka":"7","nviid":"1303"},
        {"id":"87","bolge":"AKDENİZ","il":"ANTALYA","ilce":"FİNİKE","plaka":"7","nviid":"1333"},
        {"id":"88","bolge":"AKDENİZ","il":"ANTALYA","ilce":"GAZİPAŞA","plaka":"7","nviid":"1337"},
        {"id":"89","bolge":"AKDENİZ","il":"ANTALYA","ilce":"GÜNDOĞMUŞ","plaka":"7","nviid":"1370"},
        {"id":"90","bolge":"AKDENİZ","il":"ANTALYA","ilce":"İBRADI","plaka":"7","nviid":"1946"},
        {"id":"91","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KAŞ","plaka":"7","nviid":"1451"},
        {"id":"92","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KEMER","plaka":"7","nviid":"1959"},
        {"id":"93","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KEPEZ","plaka":"7","nviid":"2037"},
        {"id":"94","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KONYAALTI","plaka":"7","nviid":"2038"},
        {"id":"95","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KORKUTELİ","plaka":"7","nviid":"1483"},
        {"id":"96","bolge":"AKDENİZ","il":"ANTALYA","ilce":"KUMLUCA","plaka":"7","nviid":"1492"},
        {"id":"97","bolge":"AKDENİZ","il":"ANTALYA","ilce":"MANAVGAT","plaka":"7","nviid":"1512"},
        {"id":"98","bolge":"AKDENİZ","il":"ANTALYA","ilce":"MURATPAŞA","plaka":"7","nviid":"2039"},
        {"id":"99","bolge":"AKDENİZ","il":"ANTALYA","ilce":"SERİK","plaka":"7","nviid":"1616"},
        {"id":"100","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"MERKEZ","plaka":"75","nviid":"1144"},
        {"id":"101","bolge":"KARADENİZ","il":"ARTVİN","ilce":"ARDANUÇ","plaka":"8","nviid":"1145"},
        {"id":"102","bolge":"KARADENİZ","il":"ARTVİN","ilce":"ARHAVİ","plaka":"8","nviid":"1147"},
        {"id":"103","bolge":"KARADENİZ","il":"ARTVİN","ilce":"BORÇKA","plaka":"8","nviid":"1202"},
        {"id":"104","bolge":"KARADENİZ","il":"ARTVİN","ilce":"HOPA","plaka":"8","nviid":"1395"},
        {"id":"105","bolge":"KARADENİZ","il":"ARTVİN","ilce":"MERKEZ","plaka":"8","nviid":"1152"},
        {"id":"106","bolge":"KARADENİZ","il":"ARTVİN","ilce":"MURGUL","plaka":"8","nviid":"1828"},
        {"id":"107","bolge":"KARADENİZ","il":"ARTVİN","ilce":"ŞAVŞAT","plaka":"8","nviid":"1653"},
        {"id":"108","bolge":"KARADENİZ","il":"ARTVİN","ilce":"YUSUFELİ","plaka":"8","nviid":"1736"},
        {"id":"109","bolge":"EGE","il":"AYDIN","ilce":"BOZDOĞAN","plaka":"9","nviid":"1206"},
        {"id":"110","bolge":"EGE","il":"AYDIN","ilce":"BUHARKENT","plaka":"9","nviid":"1781"},
        {"id":"111","bolge":"EGE","il":"AYDIN","ilce":"ÇİNE","plaka":"9","nviid":"1256"},
        {"id":"112","bolge":"EGE","il":"AYDIN","ilce":"DİDİM","plaka":"9","nviid":"2000"},
        {"id":"113","bolge":"EGE","il":"AYDIN","ilce":"GERMENCİK","plaka":"9","nviid":"1348"},
        {"id":"114","bolge":"EGE","il":"AYDIN","ilce":"İNCİRLİOVA","plaka":"9","nviid":"1807"},
        {"id":"115","bolge":"EGE","il":"AYDIN","ilce":"KARACASU","plaka":"9","nviid":"1435"},
        {"id":"116","bolge":"EGE","il":"AYDIN","ilce":"KARPUZLU","plaka":"9","nviid":"1957"},
        {"id":"117","bolge":"EGE","il":"AYDIN","ilce":"KOÇARLI","plaka":"9","nviid":"1479"},
        {"id":"118","bolge":"EGE","il":"AYDIN","ilce":"KÖŞK","plaka":"9","nviid":"1968"},
        {"id":"119","bolge":"EGE","il":"AYDIN","ilce":"KUŞADASI","plaka":"9","nviid":"1497"},
        {"id":"120","bolge":"EGE","il":"AYDIN","ilce":"KUYUCAK","plaka":"9","nviid":"1498"},
        {"id":"121","bolge":"EGE","il":"AYDIN","ilce":"NAZİLLİ","plaka":"9","nviid":"1542"},
        {"id":"122","bolge":"EGE","il":"AYDIN","ilce":"SÖKE","plaka":"9","nviid":"1637"},
        {"id":"123","bolge":"EGE","il":"AYDIN","ilce":"SULTANHİSAR","plaka":"9","nviid":"1640"},
        {"id":"124","bolge":"EGE","il":"AYDIN","ilce":"YENİPAZAR","plaka":"9","nviid":"1724"},
        {"id":"125","bolge":"MARMARA","il":"BALIKESİR","ilce":"AYVALIK","plaka":"10","nviid":"1161"},
        {"id":"126","bolge":"MARMARA","il":"BALIKESİR","ilce":"BALYA","plaka":"10","nviid":"1169"},
        {"id":"127","bolge":"MARMARA","il":"BALIKESİR","ilce":"BANDIRMA","plaka":"10","nviid":"1171"},
        {"id":"128","bolge":"MARMARA","il":"BALIKESİR","ilce":"BİGADİÇ","plaka":"10","nviid":"1191"},
        {"id":"129","bolge":"MARMARA","il":"BALIKESİR","ilce":"BURHANİYE","plaka":"10","nviid":"1216"},
        {"id":"130","bolge":"MARMARA","il":"BALIKESİR","ilce":"DURSUNBEY","plaka":"10","nviid":"1291"},
        {"id":"131","bolge":"MARMARA","il":"BALIKESİR","ilce":"EDREMİT","plaka":"10","nviid":"1294"},
        {"id":"132","bolge":"MARMARA","il":"BALIKESİR","ilce":"ERDEK","plaka":"10","nviid":"1310"},
        {"id":"133","bolge":"MARMARA","il":"BALIKESİR","ilce":"GÖMEÇ","plaka":"10","nviid":"1928"},
        {"id":"134","bolge":"MARMARA","il":"BALIKESİR","ilce":"GÖNEN","plaka":"10","nviid":"1360"},
        {"id":"135","bolge":"MARMARA","il":"BALIKESİR","ilce":"HAVRAN","plaka":"10","nviid":"1384"},
        {"id":"136","bolge":"MARMARA","il":"BALIKESİR","ilce":"İVRİNDİ","plaka":"10","nviid":"1418"},
        {"id":"137","bolge":"MARMARA","il":"BALIKESİR","ilce":"KEPSUT","plaka":"10","nviid":"1462"},
        {"id":"138","bolge":"MARMARA","il":"BALIKESİR","ilce":"MANYAS","plaka":"10","nviid":"1514"},
        {"id":"139","bolge":"MARMARA","il":"BALIKESİR","ilce":"MARMARA","plaka":"10","nviid":"1824"},
        {"id":"140","bolge":"MARMARA","il":"BALIKESİR","ilce":"SAVAŞTEPE","plaka":"10","nviid":"1608"},
        {"id":"141","bolge":"MARMARA","il":"BALIKESİR","ilce":"SINDIRGI","plaka":"10","nviid":"1619"},
        {"id":"142","bolge":"MARMARA","il":"BALIKESİR","ilce":"SUSURLUK","plaka":"10","nviid":"1644"},
        {"id":"143","bolge":"KARADENİZ","il":"BARTIN","ilce":"MERKEZ","plaka":"74","nviid":"1172"},
        {"id":"144","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"MERKEZ","plaka":"72","nviid":"1174"},
        {"id":"145","bolge":"KARADENİZ","il":"BAYBURT","ilce":"MERKEZ","plaka":"69","nviid":"1176"},
        {"id":"146","bolge":"MARMARA","il":"BİLECİK","ilce":"BOZÜYÜK","plaka":"11","nviid":"1210"},
        {"id":"147","bolge":"MARMARA","il":"BİLECİK","ilce":"GÖLPAZARI","plaka":"11","nviid":"1359"},
        {"id":"148","bolge":"MARMARA","il":"BİLECİK","ilce":"İNHİSAR","plaka":"11","nviid":"1948"},
        {"id":"149","bolge":"MARMARA","il":"BİLECİK","ilce":"MERKEZ","plaka":"11","nviid":"1192"},
        {"id":"150","bolge":"MARMARA","il":"BİLECİK","ilce":"OSMANELİ","plaka":"11","nviid":"1559"},
        {"id":"151","bolge":"MARMARA","il":"BİLECİK","ilce":"PAZARYERİ","plaka":"11","nviid":"1571"},
        {"id":"152","bolge":"MARMARA","il":"BİLECİK","ilce":"SÖĞÜT","plaka":"11","nviid":"1636"},
        {"id":"153","bolge":"MARMARA","il":"BİLECİK","ilce":"YENİPAZAR","plaka":"11","nviid":"1857"},
        {"id":"154","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"ADAKLI","plaka":"12","nviid":"1750"},
        {"id":"155","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"GENÇ","plaka":"12","nviid":"1344"},
        {"id":"156","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"KARLIOVA","plaka":"12","nviid":"1446"},
        {"id":"157","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"KİĞI","plaka":"12","nviid":"1475"},
        {"id":"158","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"MERKEZ","plaka":"12","nviid":"1193"},
        {"id":"159","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"SOLHAN","plaka":"12","nviid":"1633"},
        {"id":"160","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"YAYLADERE","plaka":"12","nviid":"1855"},
        {"id":"161","bolge":"DOĞU ANADOLU","il":"BİNGÖL","ilce":"YEDİSU","plaka":"12","nviid":"1996"},
        {"id":"162","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"ADİLCEVAZ","plaka":"13","nviid":"1106"},
        {"id":"163","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"AHLAT","plaka":"13","nviid":"1112"},
        {"id":"164","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"GÜROYMAK","plaka":"13","nviid":"1798"},
        {"id":"165","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"HİZAN","plaka":"13","nviid":"1394"},
        {"id":"166","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"MERKEZ","plaka":"13","nviid":"1196"},
        {"id":"167","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"MUTKİ","plaka":"13","nviid":"1537"},
        {"id":"168","bolge":"DOĞU ANADOLU","il":"BİTLİS","ilce":"TATVAN","plaka":"13","nviid":"1669"},
        {"id":"169","bolge":"KARADENİZ","il":"BOLU","ilce":"DÖRTDİVAN","plaka":"14","nviid":"1916"},
        {"id":"170","bolge":"KARADENİZ","il":"BOLU","ilce":"GEREDE","plaka":"14","nviid":"1346"},
        {"id":"171","bolge":"KARADENİZ","il":"BOLU","ilce":"GÖYNÜK","plaka":"14","nviid":"1364"},
        {"id":"172","bolge":"KARADENİZ","il":"BOLU","ilce":"KIBRISCIK","plaka":"14","nviid":"1466"},
        {"id":"173","bolge":"KARADENİZ","il":"BOLU","ilce":"MENGEN","plaka":"14","nviid":"1522"},
        {"id":"174","bolge":"KARADENİZ","il":"BOLU","ilce":"MERKEZ","plaka":"14","nviid":"1199"},
        {"id":"175","bolge":"KARADENİZ","il":"BOLU","ilce":"MUDURNU","plaka":"14","nviid":"1531"},
        {"id":"176","bolge":"KARADENİZ","il":"BOLU","ilce":"SEBEN","plaka":"14","nviid":"1610"},
        {"id":"177","bolge":"KARADENİZ","il":"BOLU","ilce":"YENİÇAĞA","plaka":"14","nviid":"1997"},
        {"id":"178","bolge":"AKDENİZ","il":"BURDUR","ilce":"AĞLASUN","plaka":"15","nviid":"1109"},
        {"id":"179","bolge":"AKDENİZ","il":"BURDUR","ilce":"ALTINYAYLA","plaka":"15","nviid":"1874"},
        {"id":"180","bolge":"AKDENİZ","il":"BURDUR","ilce":"BUCAK","plaka":"15","nviid":"1211"},
        {"id":"181","bolge":"AKDENİZ","il":"BURDUR","ilce":"ÇAVDIR","plaka":"15","nviid":"1899"},
        {"id":"182","bolge":"AKDENİZ","il":"BURDUR","ilce":"ÇELTİKÇİ","plaka":"15","nviid":"1903"},
        {"id":"183","bolge":"AKDENİZ","il":"BURDUR","ilce":"GÖLHİSAR","plaka":"15","nviid":"1357"},
        {"id":"184","bolge":"AKDENİZ","il":"BURDUR","ilce":"KARAMANLI","plaka":"15","nviid":"1813"},
        {"id":"185","bolge":"AKDENİZ","il":"BURDUR","ilce":"KEMER","plaka":"15","nviid":"1816"},
        {"id":"186","bolge":"AKDENİZ","il":"BURDUR","ilce":"MERKEZ","plaka":"15","nviid":"1215"},
        {"id":"187","bolge":"AKDENİZ","il":"BURDUR","ilce":"TEFENNİ","plaka":"15","nviid":"1672"},
        {"id":"188","bolge":"AKDENİZ","il":"BURDUR","ilce":"YEŞİLOVA","plaka":"15","nviid":"1728"},
        {"id":"189","bolge":"MARMARA","il":"BURSA","ilce":"BÜYÜKORHAN","plaka":"16","nviid":"1783"},
        {"id":"190","bolge":"MARMARA","il":"BURSA","ilce":"GEMLİK","plaka":"16","nviid":"1343"},
        {"id":"191","bolge":"MARMARA","il":"BURSA","ilce":"GÜRSU","plaka":"16","nviid":"1935"},
        {"id":"192","bolge":"MARMARA","il":"BURSA","ilce":"HARMANCIK","plaka":"16","nviid":"1799"},
        {"id":"193","bolge":"MARMARA","il":"BURSA","ilce":"İNEGÖL","plaka":"16","nviid":"1411"},
        {"id":"194","bolge":"MARMARA","il":"BURSA","ilce":"İZNİK","plaka":"16","nviid":"1420"},
        {"id":"195","bolge":"MARMARA","il":"BURSA","ilce":"KARACABEY","plaka":"16","nviid":"1434"},
        {"id":"196","bolge":"MARMARA","il":"BURSA","ilce":"KELES","plaka":"16","nviid":"1457"},
        {"id":"197","bolge":"MARMARA","il":"BURSA","ilce":"KESTEL","plaka":"16","nviid":"1960"},
        {"id":"198","bolge":"MARMARA","il":"BURSA","ilce":"MUDANYA","plaka":"16","nviid":"1530"},
        {"id":"199","bolge":"MARMARA","il":"BURSA","ilce":"MUSTAFAKEMALPAŞA","plaka":"16","nviid":"1535"},
        {"id":"200","bolge":"MARMARA","il":"BURSA","ilce":"NİLÜFER","plaka":"16","nviid":"1829"},
        {"id":"201","bolge":"MARMARA","il":"BURSA","ilce":"ORHANELİ","plaka":"16","nviid":"1553"},
        {"id":"202","bolge":"MARMARA","il":"BURSA","ilce":"ORHANGAZİ","plaka":"16","nviid":"1554"},
        {"id":"203","bolge":"MARMARA","il":"BURSA","ilce":"OSMANGAZİ","plaka":"16","nviid":"1832"},
        {"id":"204","bolge":"MARMARA","il":"BURSA","ilce":"YENİŞEHİR","plaka":"16","nviid":"1725"},
        {"id":"205","bolge":"MARMARA","il":"BURSA","ilce":"YILDIRIM","plaka":"16","nviid":"1859"},
        {"id":"206","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"AYVACIK","plaka":"17","nviid":"1160"},
        {"id":"207","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"BAYRAMİÇ","plaka":"17","nviid":"1180"},
        {"id":"208","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"BİGA","plaka":"17","nviid":"1190"},
        {"id":"209","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"BOZCAADA","plaka":"17","nviid":"1205"},
        {"id":"210","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"ÇAN","plaka":"17","nviid":"1229"},
        {"id":"211","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"ECEABAT","plaka":"17","nviid":"1293"},
        {"id":"212","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"EZİNE","plaka":"17","nviid":"1326"},
        {"id":"213","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"GELİBOLU","plaka":"17","nviid":"1340"},
        {"id":"214","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"GÖKÇEADA","plaka":"17","nviid":"1408"},
        {"id":"215","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"LAPSEKİ","plaka":"17","nviid":"1503"},
        {"id":"216","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"MERKEZ","plaka":"17","nviid":"1230"},
        {"id":"217","bolge":"MARMARA","il":"ÇANAKKALE","ilce":"YENİCE","plaka":"17","nviid":"1722"},
        {"id":"218","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ATKARACALAR","plaka":"18","nviid":"1765"},
        {"id":"219","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"BAYRAMÖREN","plaka":"18","nviid":"1885"},
        {"id":"220","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ÇERKEŞ","plaka":"18","nviid":"1248"},
        {"id":"221","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ELDİVAN","plaka":"18","nviid":"1300"},
        {"id":"222","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ILGAZ","plaka":"18","nviid":"1399"},
        {"id":"223","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"KIZILIRMAK","plaka":"18","nviid":"1817"},
        {"id":"224","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"KORGUN","plaka":"18","nviid":"1963"},
        {"id":"225","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"KURŞUNLU","plaka":"18","nviid":"1494"},
        {"id":"226","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"MERKEZ","plaka":"18","nviid":"1232"},
        {"id":"227","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ORTA","plaka":"18","nviid":"1555"},
        {"id":"228","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"ŞABANÖZÜ","plaka":"18","nviid":"1649"},
        {"id":"229","bolge":"İÇ ANADOLU","il":"ÇANKIRI","ilce":"YAPRAKLI","plaka":"18","nviid":"1718"},
        {"id":"230","bolge":"KARADENİZ","il":"ÇORUM","ilce":"ALACA","plaka":"19","nviid":"1124"},
        {"id":"231","bolge":"KARADENİZ","il":"ÇORUM","ilce":"BAYAT","plaka":"19","nviid":"1177"},
        {"id":"232","bolge":"KARADENİZ","il":"ÇORUM","ilce":"BOĞAZKALE","plaka":"19","nviid":"1778"},
        {"id":"233","bolge":"KARADENİZ","il":"ÇORUM","ilce":"DODURGA","plaka":"19","nviid":"1911"},
        {"id":"234","bolge":"KARADENİZ","il":"ÇORUM","ilce":"İSKİLİP","plaka":"19","nviid":"1414"},
        {"id":"235","bolge":"KARADENİZ","il":"ÇORUM","ilce":"KARGI","plaka":"19","nviid":"1445"},
        {"id":"236","bolge":"KARADENİZ","il":"ÇORUM","ilce":"LAÇİN","plaka":"19","nviid":"1972"},
        {"id":"237","bolge":"KARADENİZ","il":"ÇORUM","ilce":"MECİTÖZÜ","plaka":"19","nviid":"1520"},
        {"id":"238","bolge":"KARADENİZ","il":"ÇORUM","ilce":"MERKEZ","plaka":"19","nviid":"1259"},
        {"id":"239","bolge":"KARADENİZ","il":"ÇORUM","ilce":"OĞUZLAR","plaka":"19","nviid":"1976"},
        {"id":"240","bolge":"KARADENİZ","il":"ÇORUM","ilce":"ORTAKÖY","plaka":"19","nviid":"1556"},
        {"id":"241","bolge":"KARADENİZ","il":"ÇORUM","ilce":"OSMANCIK","plaka":"19","nviid":"1558"},
        {"id":"242","bolge":"KARADENİZ","il":"ÇORUM","ilce":"SUNGURLU","plaka":"19","nviid":"1642"},
        {"id":"243","bolge":"KARADENİZ","il":"ÇORUM","ilce":"UĞURLUDAĞ","plaka":"19","nviid":"1850"},
        {"id":"244","bolge":"EGE","il":"DENİZLİ","ilce":"ACIPAYAM","plaka":"20","nviid":"1102"},
        {"id":"245","bolge":"EGE","il":"DENİZLİ","ilce":"BABADAĞ","plaka":"20","nviid":"1769"},
        {"id":"246","bolge":"EGE","il":"DENİZLİ","ilce":"BAKLAN","plaka":"20","nviid":"1881"},
        {"id":"247","bolge":"EGE","il":"DENİZLİ","ilce":"BEKİLLİ","plaka":"20","nviid":"1774"},
        {"id":"248","bolge":"EGE","il":"DENİZLİ","ilce":"BEYAĞAÇ","plaka":"20","nviid":"1888"},
        {"id":"249","bolge":"EGE","il":"DENİZLİ","ilce":"BOZKURT","plaka":"20","nviid":"1889"},
        {"id":"250","bolge":"EGE","il":"DENİZLİ","ilce":"BULDAN","plaka":"20","nviid":"1214"},
        {"id":"251","bolge":"EGE","il":"DENİZLİ","ilce":"ÇAL","plaka":"20","nviid":"1224"},
        {"id":"252","bolge":"EGE","il":"DENİZLİ","ilce":"ÇAMELİ","plaka":"20","nviid":"1226"},
        {"id":"253","bolge":"EGE","il":"DENİZLİ","ilce":"ÇARDAK","plaka":"20","nviid":"1233"},
        {"id":"254","bolge":"EGE","il":"DENİZLİ","ilce":"ÇİVRİL","plaka":"20","nviid":"1257"},
        {"id":"255","bolge":"EGE","il":"DENİZLİ","ilce":"GÜNEY","plaka":"20","nviid":"1371"},
        {"id":"256","bolge":"EGE","il":"DENİZLİ","ilce":"HONAZ","plaka":"20","nviid":"1803"},
        {"id":"257","bolge":"EGE","il":"DENİZLİ","ilce":"KALE","plaka":"20","nviid":"1426"},
        {"id":"258","bolge":"EGE","il":"DENİZLİ","ilce":"PAMUKKALE","plaka":"20","nviid":"1871"},
        {"id":"259","bolge":"EGE","il":"DENİZLİ","ilce":"SARAYKÖY","plaka":"20","nviid":"1597"},
        {"id":"260","bolge":"EGE","il":"DENİZLİ","ilce":"SERİNHİSAR","plaka":"20","nviid":"1840"},
        {"id":"261","bolge":"EGE","il":"DENİZLİ","ilce":"TAVAS","plaka":"20","nviid":"1670"},
        {"id":"262","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"BAĞLAR","plaka":"21","nviid":"2040"},
        {"id":"263","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"BİSMİL","plaka":"21","nviid":"1195"},
        {"id":"264","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"ÇERMİK","plaka":"21","nviid":"1249"},
        {"id":"265","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"ÇINAR","plaka":"21","nviid":"1253"},
        {"id":"266","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"ÇÜNGÜŞ","plaka":"21","nviid":"1263"},
        {"id":"267","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"DİCLE","plaka":"21","nviid":"1278"},
        {"id":"268","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"EĞİL","plaka":"21","nviid":"1791"},
        {"id":"269","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"ERGANİ","plaka":"21","nviid":"1315"},
        {"id":"270","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"HANİ","plaka":"21","nviid":"1381"},
        {"id":"271","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"HAZRO","plaka":"21","nviid":"1389"},
        {"id":"272","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"KAYAPINAR","plaka":"21","nviid":"2041"},
        {"id":"273","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"KOCAKÖY","plaka":"21","nviid":"1962"},
        {"id":"274","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"KULP","plaka":"21","nviid":"1490"},
        {"id":"275","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"LİCE","plaka":"21","nviid":"1504"},
        {"id":"276","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"SİLVAN","plaka":"21","nviid":"1624"},
        {"id":"277","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"SUR","plaka":"21","nviid":"2042"},
        {"id":"278","bolge":"GÜNEYDOĞU ANADOLU","il":"DİYARBAKIR","ilce":"YENİŞEHİR","plaka":"21","nviid":"2043"},
        {"id":"279","bolge":"KARADENİZ","il":"DÜZCE","ilce":"MERKEZ","plaka":"81","nviid":"1292"},
        {"id":"280","bolge":"MARMARA","il":"EDİRNE","ilce":"ENEZ","plaka":"22","nviid":"1307"},
        {"id":"281","bolge":"MARMARA","il":"EDİRNE","ilce":"HAVSA","plaka":"22","nviid":"1385"},
        {"id":"282","bolge":"MARMARA","il":"EDİRNE","ilce":"İPSALA","plaka":"22","nviid":"1412"},
        {"id":"283","bolge":"MARMARA","il":"EDİRNE","ilce":"KEŞAN","plaka":"22","nviid":"1464"},
        {"id":"284","bolge":"MARMARA","il":"EDİRNE","ilce":"LALAPAŞA","plaka":"22","nviid":"1502"},
        {"id":"285","bolge":"MARMARA","il":"EDİRNE","ilce":"MERİÇ","plaka":"22","nviid":"1523"},
        {"id":"286","bolge":"MARMARA","il":"EDİRNE","ilce":"MERKEZ","plaka":"22","nviid":"1295"},
        {"id":"287","bolge":"MARMARA","il":"EDİRNE","ilce":"SÜLOĞLU","plaka":"22","nviid":"1988"},
        {"id":"288","bolge":"MARMARA","il":"EDİRNE","ilce":"UZUNKÖPRÜ","plaka":"22","nviid":"1705"},
        {"id":"289","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"AĞIN","plaka":"23","nviid":"1110"},
        {"id":"290","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"ALACAKAYA","plaka":"23","nviid":"1873"},
        {"id":"291","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"ARICAK","plaka":"23","nviid":"1762"},
        {"id":"292","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"BASKİL","plaka":"23","nviid":"1173"},
        {"id":"293","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"KARAKOÇAN","plaka":"23","nviid":"1438"},
        {"id":"294","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"KEBAN","plaka":"23","nviid":"1455"},
        {"id":"295","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"KOVANCILAR","plaka":"23","nviid":"1820"},
        {"id":"296","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"MADEN","plaka":"23","nviid":"1506"},
        {"id":"297","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"MERKEZ","plaka":"23","nviid":"1298"},
        {"id":"298","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"PALU","plaka":"23","nviid":"1566"},
        {"id":"299","bolge":"DOĞU ANADOLU","il":"ELAZIĞ","ilce":"SİVRİCE","plaka":"23","nviid":"1631"},
        {"id":"300","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"ÇAYIRLI","plaka":"24","nviid":"1243"},
        {"id":"301","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"İLİÇ","plaka":"24","nviid":"1406"},
        {"id":"302","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"KEMAH","plaka":"24","nviid":"1459"},
        {"id":"303","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"KEMALİYE","plaka":"24","nviid":"1460"},
        {"id":"304","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"MERKEZ","plaka":"24","nviid":"1318"},
        {"id":"305","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"OTLUKBELİ","plaka":"24","nviid":"1977"},
        {"id":"306","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"REFAHİYE","plaka":"24","nviid":"1583"},
        {"id":"307","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"TERCAN","plaka":"24","nviid":"1675"},
        {"id":"308","bolge":"DOĞU ANADOLU","il":"ERZİNCAN","ilce":"ÜZÜMLÜ","plaka":"24","nviid":"1853"},
        {"id":"309","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"AŞKALE","plaka":"25","nviid":"1153"},
        {"id":"310","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"AZİZİYE","plaka":"25","nviid":"1945"},
        {"id":"311","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"ÇAT","plaka":"25","nviid":"1235"},
        {"id":"312","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"HINIS","plaka":"25","nviid":"1392"},
        {"id":"313","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"HORASAN","plaka":"25","nviid":"1396"},
        {"id":"314","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"İSPİR","plaka":"25","nviid":"1416"},
        {"id":"315","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"KARAÇOBAN","plaka":"25","nviid":"1812"},
        {"id":"316","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"KARAYAZI","plaka":"25","nviid":"1444"},
        {"id":"317","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"KÖPRÜKÖY","plaka":"25","nviid":"1967"},
        {"id":"318","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"NARMAN","plaka":"25","nviid":"1540"},
        {"id":"319","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"OLTU","plaka":"25","nviid":"1550"},
        {"id":"320","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"OLUR","plaka":"25","nviid":"1551"},
        {"id":"321","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"PALANDÖKEN","plaka":"25","nviid":"2044"},
        {"id":"322","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"PASİNLER","plaka":"25","nviid":"1567"},
        {"id":"323","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"PAZARYOLU","plaka":"25","nviid":"1865"},
        {"id":"324","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"ŞENKAYA","plaka":"25","nviid":"1657"},
        {"id":"325","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"TEKMAN","plaka":"25","nviid":"1674"},
        {"id":"326","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"TORTUM","plaka":"25","nviid":"1683"},
        {"id":"327","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"UZUNDERE","plaka":"25","nviid":"1851"},
        {"id":"328","bolge":"DOĞU ANADOLU","il":"ERZURUM","ilce":"YAKUTİYE","plaka":"25","nviid":"2045"},
        {"id":"329","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"ALPU","plaka":"26","nviid":"1759"},
        {"id":"330","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"BEYLİKOVA","plaka":"26","nviid":"1777"},
        {"id":"331","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"ÇİFTELER","plaka":"26","nviid":"1255"},
        {"id":"332","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"GÜNYÜZÜ","plaka":"26","nviid":"1934"},
        {"id":"333","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"HAN","plaka":"26","nviid":"1939"},
        {"id":"334","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"İNÖNÜ","plaka":"26","nviid":"1808"},
        {"id":"335","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"MAHMUDİYE","plaka":"26","nviid":"1508"},
        {"id":"336","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"MİHALGAZİ","plaka":"26","nviid":"1973"},
        {"id":"337","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"MİHALIÇÇIK","plaka":"26","nviid":"1527"},
        {"id":"338","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"ODUNPAZARI","plaka":"26","nviid":"2046"},
        {"id":"339","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"SARICAKAYA","plaka":"26","nviid":"1599"},
        {"id":"340","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"SEYİTGAZİ","plaka":"26","nviid":"1618"},
        {"id":"341","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"SİVRİHİSAR","plaka":"26","nviid":"1632"},
        {"id":"342","bolge":"İÇ ANADOLU","il":"ESKİŞEHİR","ilce":"TEPEBAŞI","plaka":"26","nviid":"2047"},
        {"id":"343","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"ARABAN","plaka":"27","nviid":"1139"},
        {"id":"344","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"İSLAHİYE","plaka":"27","nviid":"1415"},
        {"id":"345","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"KARKAMIŞ","plaka":"27","nviid":"1956"},
        {"id":"346","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"NİZİP","plaka":"27","nviid":"1546"},
        {"id":"347","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"NURDAĞI","plaka":"27","nviid":"1974"},
        {"id":"348","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"OĞUZELİ","plaka":"27","nviid":"1549"},
        {"id":"349","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"ŞAHİNBEY","plaka":"27","nviid":"1841"},
        {"id":"350","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"ŞEHİTKAMİL","plaka":"27","nviid":"1844"},
        {"id":"351","bolge":"GÜNEYDOĞU ANADOLU","il":"GAZİANTEP","ilce":"YAVUZELİ","plaka":"27","nviid":"1720"},
        {"id":"352","bolge":"KARADENİZ","il":"GİRESUN","ilce":"ALUCRA","plaka":"28","nviid":"1133"},
        {"id":"353","bolge":"KARADENİZ","il":"GİRESUN","ilce":"BULANCAK","plaka":"28","nviid":"1212"},
        {"id":"354","bolge":"KARADENİZ","il":"GİRESUN","ilce":"ÇAMOLUK","plaka":"28","nviid":"1893"},
        {"id":"355","bolge":"KARADENİZ","il":"GİRESUN","ilce":"ÇANAKÇI","plaka":"28","nviid":"1894"},
        {"id":"356","bolge":"KARADENİZ","il":"GİRESUN","ilce":"DERELİ","plaka":"28","nviid":"1272"},
        {"id":"357","bolge":"KARADENİZ","il":"GİRESUN","ilce":"DOĞANKENT","plaka":"28","nviid":"1912"},
        {"id":"358","bolge":"KARADENİZ","il":"GİRESUN","ilce":"ESPİYE","plaka":"28","nviid":"1320"},
        {"id":"359","bolge":"KARADENİZ","il":"GİRESUN","ilce":"EYNESİL","plaka":"28","nviid":"1324"},
        {"id":"360","bolge":"KARADENİZ","il":"GİRESUN","ilce":"GÖRELE","plaka":"28","nviid":"1361"},
        {"id":"361","bolge":"KARADENİZ","il":"GİRESUN","ilce":"GÜCE","plaka":"28","nviid":"1930"},
        {"id":"362","bolge":"KARADENİZ","il":"GİRESUN","ilce":"KEŞAP","plaka":"28","nviid":"1465"},
        {"id":"363","bolge":"KARADENİZ","il":"GİRESUN","ilce":"MERKEZ","plaka":"28","nviid":"1352"},
        {"id":"364","bolge":"KARADENİZ","il":"GİRESUN","ilce":"PİRAZİZ","plaka":"28","nviid":"1837"},
        {"id":"365","bolge":"KARADENİZ","il":"GİRESUN","ilce":"ŞEBİNKARAHİSAR","plaka":"28","nviid":"1654"},
        {"id":"366","bolge":"KARADENİZ","il":"GİRESUN","ilce":"TİREBOLU","plaka":"28","nviid":"1678"},
        {"id":"367","bolge":"KARADENİZ","il":"GİRESUN","ilce":"YAĞLIDERE","plaka":"28","nviid":"1854"},
        {"id":"368","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"KELKİT","plaka":"29","nviid":"1458"},
        {"id":"369","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"KÖSE","plaka":"29","nviid":"1822"},
        {"id":"370","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"KÜRTÜN","plaka":"29","nviid":"1971"},
        {"id":"371","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"MERKEZ","plaka":"29","nviid":"1369"},
        {"id":"372","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"ŞİRAN","plaka":"29","nviid":"1660"},
        {"id":"373","bolge":"KARADENİZ","il":"GÜMÜŞHANE","ilce":"TORUL","plaka":"29","nviid":"1684"},
        {"id":"374","bolge":"DOĞU ANADOLU","il":"HAKKARİ","ilce":"ÇUKURCA","plaka":"30","nviid":"1261"},
        {"id":"375","bolge":"DOĞU ANADOLU","il":"HAKKARİ","ilce":"MERKEZ","plaka":"30","nviid":"1377"},
        {"id":"376","bolge":"DOĞU ANADOLU","il":"HAKKARİ","ilce":"ŞEMDİNLİ","plaka":"30","nviid":"1656"},
        {"id":"377","bolge":"DOĞU ANADOLU","il":"HAKKARİ","ilce":"YÜKSEKOVA","plaka":"30","nviid":"1737"},
        {"id":"378","bolge":"AKDENİZ","il":"HATAY","ilce":"ALTINÖZÜ","plaka":"31","nviid":"1131"},
        {"id":"379","bolge":"AKDENİZ","il":"HATAY","ilce":"BELEN","plaka":"31","nviid":"1887"},
        {"id":"380","bolge":"AKDENİZ","il":"HATAY","ilce":"DÖRTYOL","plaka":"31","nviid":"1289"},
        {"id":"381","bolge":"AKDENİZ","il":"HATAY","ilce":"ERZİN","plaka":"31","nviid":"1792"},
        {"id":"382","bolge":"AKDENİZ","il":"HATAY","ilce":"HASSA","plaka":"31","nviid":"1382"},
        {"id":"383","bolge":"AKDENİZ","il":"HATAY","ilce":"İSKENDERUN","plaka":"31","nviid":"1413"},
        {"id":"384","bolge":"AKDENİZ","il":"HATAY","ilce":"KIRIKHAN","plaka":"31","nviid":"1468"},
        {"id":"385","bolge":"AKDENİZ","il":"HATAY","ilce":"KUMLU","plaka":"31","nviid":"1970"},
        {"id":"386","bolge":"AKDENİZ","il":"HATAY","ilce":"REYHANLI","plaka":"31","nviid":"1585"},
        {"id":"387","bolge":"AKDENİZ","il":"HATAY","ilce":"SAMANDAĞ","plaka":"31","nviid":"1591"},
        {"id":"388","bolge":"AKDENİZ","il":"HATAY","ilce":"YAYLADAĞI","plaka":"31","nviid":"1721"},
        {"id":"389","bolge":"DOĞU ANADOLU","il":"IĞDIR","ilce":"MERKEZ","plaka":"76","nviid":"1398"},
        {"id":"390","bolge":"AKDENİZ","il":"ISPARTA","ilce":"AKSU","plaka":"32","nviid":"1755"},
        {"id":"391","bolge":"AKDENİZ","il":"ISPARTA","ilce":"ATABEY","plaka":"32","nviid":"1154"},
        {"id":"392","bolge":"AKDENİZ","il":"ISPARTA","ilce":"EĞİRDİR","plaka":"32","nviid":"1297"},
        {"id":"393","bolge":"AKDENİZ","il":"ISPARTA","ilce":"GELENDOST","plaka":"32","nviid":"1341"},
        {"id":"394","bolge":"AKDENİZ","il":"ISPARTA","ilce":"GÖNEN","plaka":"32","nviid":"1929"},
        {"id":"395","bolge":"AKDENİZ","il":"ISPARTA","ilce":"KEÇİBORLU","plaka":"32","nviid":"1456"},
        {"id":"396","bolge":"AKDENİZ","il":"ISPARTA","ilce":"MERKEZ","plaka":"32","nviid":"1401"},
        {"id":"397","bolge":"AKDENİZ","il":"ISPARTA","ilce":"SENİRKENT","plaka":"32","nviid":"1615"},
        {"id":"398","bolge":"AKDENİZ","il":"ISPARTA","ilce":"SÜTÇÜLER","plaka":"32","nviid":"1648"},
        {"id":"399","bolge":"AKDENİZ","il":"ISPARTA","ilce":"ŞARKİKARAAĞAÇ","plaka":"32","nviid":"1651"},
        {"id":"400","bolge":"AKDENİZ","il":"ISPARTA","ilce":"ULUBORLU","plaka":"32","nviid":"1699"},
        {"id":"401","bolge":"AKDENİZ","il":"ISPARTA","ilce":"YALVAÇ","plaka":"32","nviid":"1717"},
        {"id":"402","bolge":"AKDENİZ","il":"ISPARTA","ilce":"YENİŞARBADEMLİ","plaka":"32","nviid":"2001"},
        {"id":"403","bolge":"MARMARA","il":"İSTANBUL","ilce":"ADALAR","plaka":"34","nviid":"1103"},
        {"id":"404","bolge":"MARMARA","il":"İSTANBUL","ilce":"ARNAVUTKÖY","plaka":"34","nviid":"2048"},
        {"id":"405","bolge":"MARMARA","il":"İSTANBUL","ilce":"ATAŞEHİR","plaka":"34","nviid":"2049"},
        {"id":"406","bolge":"MARMARA","il":"İSTANBUL","ilce":"AVCILAR","plaka":"34","nviid":"2003"},
        {"id":"407","bolge":"MARMARA","il":"İSTANBUL","ilce":"BAĞCILAR","plaka":"34","nviid":"2004"},
        {"id":"408","bolge":"MARMARA","il":"İSTANBUL","ilce":"BAHÇELİEVLER","plaka":"34","nviid":"2005"},
        {"id":"409","bolge":"MARMARA","il":"İSTANBUL","ilce":"BAKIRKÖY","plaka":"34","nviid":"1166"},
        {"id":"410","bolge":"MARMARA","il":"İSTANBUL","ilce":"BAŞAKŞEHİR","plaka":"34","nviid":"2050"},
        {"id":"411","bolge":"MARMARA","il":"İSTANBUL","ilce":"BAYRAMPAŞA","plaka":"34","nviid":"1886"},
        {"id":"412","bolge":"MARMARA","il":"İSTANBUL","ilce":"BEŞİKTAŞ","plaka":"34","nviid":"1183"},
        {"id":"413","bolge":"MARMARA","il":"İSTANBUL","ilce":"BEYKOZ","plaka":"34","nviid":"1185"},
        {"id":"414","bolge":"MARMARA","il":"İSTANBUL","ilce":"BEYLİKDÜZÜ","plaka":"34","nviid":"2051"},
        {"id":"415","bolge":"MARMARA","il":"İSTANBUL","ilce":"BEYOĞLU","plaka":"34","nviid":"1186"},
        {"id":"416","bolge":"MARMARA","il":"İSTANBUL","ilce":"BÜYÜKÇEKMECE","plaka":"34","nviid":"1782"},
        {"id":"417","bolge":"MARMARA","il":"İSTANBUL","ilce":"ÇATALCA","plaka":"34","nviid":"1237"},
        {"id":"418","bolge":"MARMARA","il":"İSTANBUL","ilce":"ÇEKMEKÖY","plaka":"34","nviid":"2052"},
        {"id":"419","bolge":"MARMARA","il":"İSTANBUL","ilce":"ESENLER","plaka":"34","nviid":"2016"},
        {"id":"420","bolge":"MARMARA","il":"İSTANBUL","ilce":"ESENYURT","plaka":"34","nviid":"2053"},
        {"id":"421","bolge":"MARMARA","il":"İSTANBUL","ilce":"EYÜP","plaka":"34","nviid":"1325"},
        {"id":"422","bolge":"MARMARA","il":"İSTANBUL","ilce":"FATİH","plaka":"34","nviid":"1327"},
        {"id":"423","bolge":"MARMARA","il":"İSTANBUL","ilce":"GAZİOSMANPAŞA","plaka":"34","nviid":"1336"},
        {"id":"424","bolge":"MARMARA","il":"İSTANBUL","ilce":"GÜNGÖREN","plaka":"34","nviid":"2010"},
        {"id":"425","bolge":"MARMARA","il":"İSTANBUL","ilce":"KADIKÖY","plaka":"34","nviid":"1421"},
        {"id":"426","bolge":"MARMARA","il":"İSTANBUL","ilce":"KAĞITHANE","plaka":"34","nviid":"1810"},
        {"id":"427","bolge":"MARMARA","il":"İSTANBUL","ilce":"KARTAL","plaka":"34","nviid":"1449"},
        {"id":"428","bolge":"MARMARA","il":"İSTANBUL","ilce":"KÜÇÜKÇEKMECE","plaka":"34","nviid":"1823"},
        {"id":"429","bolge":"MARMARA","il":"İSTANBUL","ilce":"MALTEPE","plaka":"34","nviid":"2012"},
        {"id":"430","bolge":"MARMARA","il":"İSTANBUL","ilce":"PENDİK","plaka":"34","nviid":"1835"},
        {"id":"431","bolge":"MARMARA","il":"İSTANBUL","ilce":"SANCAKTEPE","plaka":"34","nviid":"2054"},
        {"id":"432","bolge":"MARMARA","il":"İSTANBUL","ilce":"SARIYER","plaka":"34","nviid":"1604"},
        {"id":"433","bolge":"MARMARA","il":"İSTANBUL","ilce":"SİLİVRİ","plaka":"34","nviid":"1622"},
        {"id":"434","bolge":"MARMARA","il":"İSTANBUL","ilce":"SULTANBEYLİ","plaka":"34","nviid":"2014"},
        {"id":"435","bolge":"MARMARA","il":"İSTANBUL","ilce":"SULTANGAZİ","plaka":"34","nviid":"2055"},
        {"id":"436","bolge":"MARMARA","il":"İSTANBUL","ilce":"ŞİLE","plaka":"34","nviid":"1659"},
        {"id":"437","bolge":"MARMARA","il":"İSTANBUL","ilce":"ŞİŞLİ","plaka":"34","nviid":"1663"},
        {"id":"438","bolge":"MARMARA","il":"İSTANBUL","ilce":"TUZLA","plaka":"34","nviid":"2015"},
        {"id":"439","bolge":"MARMARA","il":"İSTANBUL","ilce":"ÜMRANİYE","plaka":"34","nviid":"1852"},
        {"id":"440","bolge":"MARMARA","il":"İSTANBUL","ilce":"ÜSKÜDAR","plaka":"34","nviid":"1708"},
        {"id":"441","bolge":"MARMARA","il":"İSTANBUL","ilce":"ZEYTİNBURNU","plaka":"34","nviid":"1739"},
        {"id":"442","bolge":"EGE","il":"İZMİR","ilce":"ALİAĞA","plaka":"35","nviid":"1128"},
        {"id":"443","bolge":"EGE","il":"İZMİR","ilce":"BALÇOVA","plaka":"35","nviid":"2006"},
        {"id":"444","bolge":"EGE","il":"İZMİR","ilce":"BAYINDIR","plaka":"35","nviid":"1178"},
        {"id":"445","bolge":"EGE","il":"İZMİR","ilce":"BAYRAKLI","plaka":"35","nviid":"2056"},
        {"id":"446","bolge":"EGE","il":"İZMİR","ilce":"BERGAMA","plaka":"35","nviid":"1181"},
        {"id":"447","bolge":"EGE","il":"İZMİR","ilce":"BEYDAĞ","plaka":"35","nviid":"1776"},
        {"id":"448","bolge":"EGE","il":"İZMİR","ilce":"BORNOVA","plaka":"35","nviid":"1203"},
        {"id":"449","bolge":"EGE","il":"İZMİR","ilce":"BUCA","plaka":"35","nviid":"1780"},
        {"id":"450","bolge":"EGE","il":"İZMİR","ilce":"ÇEŞME","plaka":"35","nviid":"1251"},
        {"id":"451","bolge":"EGE","il":"İZMİR","ilce":"ÇİĞLİ","plaka":"35","nviid":"2007"},
        {"id":"452","bolge":"EGE","il":"İZMİR","ilce":"DİKİLİ","plaka":"35","nviid":"1280"},
        {"id":"453","bolge":"EGE","il":"İZMİR","ilce":"FOÇA","plaka":"35","nviid":"1334"},
        {"id":"454","bolge":"EGE","il":"İZMİR","ilce":"GAZİEMİR","plaka":"35","nviid":"2009"},
        {"id":"455","bolge":"EGE","il":"İZMİR","ilce":"GÜZELBAHÇE","plaka":"35","nviid":"2018"},
        {"id":"456","bolge":"EGE","il":"İZMİR","ilce":"KARABAĞLAR","plaka":"35","nviid":"2057"},
        {"id":"457","bolge":"EGE","il":"İZMİR","ilce":"KARABURUN","plaka":"35","nviid":"1432"},
        {"id":"458","bolge":"EGE","il":"İZMİR","ilce":"KARŞIYAKA","plaka":"35","nviid":"1448"},
        {"id":"459","bolge":"EGE","il":"İZMİR","ilce":"KEMALPAŞA","plaka":"35","nviid":"1461"},
        {"id":"460","bolge":"EGE","il":"İZMİR","ilce":"KINIK","plaka":"35","nviid":"1467"},
        {"id":"461","bolge":"EGE","il":"İZMİR","ilce":"KİRAZ","plaka":"35","nviid":"1477"},
        {"id":"462","bolge":"EGE","il":"İZMİR","ilce":"KONAK","plaka":"35","nviid":"1819"},
        {"id":"463","bolge":"EGE","il":"İZMİR","ilce":"MENDERES","plaka":"35","nviid":"1826"},
        {"id":"464","bolge":"EGE","il":"İZMİR","ilce":"MENEMEN","plaka":"35","nviid":"1521"},
        {"id":"465","bolge":"EGE","il":"İZMİR","ilce":"NARLIDERE","plaka":"35","nviid":"2013"},
        {"id":"466","bolge":"EGE","il":"İZMİR","ilce":"ÖDEMİŞ","plaka":"35","nviid":"1563"},
        {"id":"467","bolge":"EGE","il":"İZMİR","ilce":"SEFERİHİSAR","plaka":"35","nviid":"1611"},
        {"id":"468","bolge":"EGE","il":"İZMİR","ilce":"SELÇUK","plaka":"35","nviid":"1612"},
        {"id":"469","bolge":"EGE","il":"İZMİR","ilce":"TİRE","plaka":"35","nviid":"1677"},
        {"id":"470","bolge":"EGE","il":"İZMİR","ilce":"TORBALI","plaka":"35","nviid":"1682"},
        {"id":"471","bolge":"EGE","il":"İZMİR","ilce":"URLA","plaka":"35","nviid":"1703"},
        {"id":"472","bolge":"KARADENİZ","il":"KARABÜK","ilce":"MERKEZ","plaka":"78","nviid":"1433"},
        {"id":"473","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"MERKEZ","plaka":"70","nviid":"1439"},
        {"id":"474","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"AKYAKA","plaka":"36","nviid":"1756"},
        {"id":"475","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"ARPAÇAY","plaka":"36","nviid":"1149"},
        {"id":"476","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"DİGOR","plaka":"36","nviid":"1279"},
        {"id":"477","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"KAĞIZMAN","plaka":"36","nviid":"1424"},
        {"id":"478","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"MERKEZ","plaka":"36","nviid":"1447"},
        {"id":"479","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"SARIKAMIŞ","plaka":"36","nviid":"1601"},
        {"id":"480","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"SELİM","plaka":"36","nviid":"1614"},
        {"id":"481","bolge":"DOĞU ANADOLU","il":"KARS","ilce":"SUSUZ","plaka":"36","nviid":"1645"},
        {"id":"482","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"ABANA","plaka":"37","nviid":"1101"},
        {"id":"483","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"AĞLI","plaka":"37","nviid":"1867"},
        {"id":"484","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"ARAÇ","plaka":"37","nviid":"1140"},
        {"id":"485","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"AZDAVAY","plaka":"37","nviid":"1162"},
        {"id":"486","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"BOZKURT","plaka":"37","nviid":"1208"},
        {"id":"487","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"CİDE","plaka":"37","nviid":"1221"},
        {"id":"488","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"ÇATALZEYTİN","plaka":"37","nviid":"1238"},
        {"id":"489","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"DADAY","plaka":"37","nviid":"1264"},
        {"id":"490","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"DEVREKANİ","plaka":"37","nviid":"1277"},
        {"id":"491","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"DOĞANYURT","plaka":"37","nviid":"1915"},
        {"id":"492","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"HANÖNÜ","plaka":"37","nviid":"1940"},
        {"id":"493","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"İHSANGAZİ","plaka":"37","nviid":"1805"},
        {"id":"494","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"İNEBOLU","plaka":"37","nviid":"1410"},
        {"id":"495","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"KÜRE","plaka":"37","nviid":"1499"},
        {"id":"496","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"MERKEZ","plaka":"37","nviid":"1450"},
        {"id":"497","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"PINARBAŞI","plaka":"37","nviid":"1836"},
        {"id":"498","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"SEYDİLER","plaka":"37","nviid":"1984"},
        {"id":"499","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"ŞENPAZAR","plaka":"37","nviid":"1845"},
        {"id":"500","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"TAŞKÖPRÜ","plaka":"37","nviid":"1666"},
        {"id":"501","bolge":"KARADENİZ","il":"KASTAMONU","ilce":"TOSYA","plaka":"37","nviid":"1685"},
        {"id":"502","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"AKKIŞLA","plaka":"38","nviid":"1752"},
        {"id":"503","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"BÜNYAN","plaka":"38","nviid":"1218"},
        {"id":"504","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"DEVELİ","plaka":"38","nviid":"1275"},
        {"id":"505","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"FELAHİYE","plaka":"38","nviid":"1330"},
        {"id":"506","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"HACILAR","plaka":"38","nviid":"1936"},
        {"id":"507","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"İNCESU","plaka":"38","nviid":"1409"},
        {"id":"508","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"KOCASİNAN","plaka":"38","nviid":"1863"},
        {"id":"509","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"MELİKGAZİ","plaka":"38","nviid":"1864"},
        {"id":"510","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"ÖZVATAN","plaka":"38","nviid":"1978"},
        {"id":"511","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"PINARBAŞI","plaka":"38","nviid":"1576"},
        {"id":"512","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"SARIOĞLAN","plaka":"38","nviid":"1603"},
        {"id":"513","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"SARIZ","plaka":"38","nviid":"1605"},
        {"id":"514","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"TALAS","plaka":"38","nviid":"1846"},
        {"id":"515","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"TOMARZA","plaka":"38","nviid":"1680"},
        {"id":"516","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"YAHYALI","plaka":"38","nviid":"1715"},
        {"id":"517","bolge":"İÇ ANADOLU","il":"KAYSERİ","ilce":"YEŞİLHİSAR","plaka":"38","nviid":"1727"},
        {"id":"518","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"MERKEZ","plaka":"71","nviid":"1469"},
        {"id":"519","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"BABAESKİ","plaka":"39","nviid":"1163"},
        {"id":"520","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"DEMİRKÖY","plaka":"39","nviid":"1270"},
        {"id":"521","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"KOFÇAZ","plaka":"39","nviid":"1480"},
        {"id":"522","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"LÜLEBURGAZ","plaka":"39","nviid":"1505"},
        {"id":"523","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"MERKEZ","plaka":"39","nviid":"1471"},
        {"id":"524","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"PEHLİVANKÖY","plaka":"39","nviid":"1572"},
        {"id":"525","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"PINARHİSAR","plaka":"39","nviid":"1577"},
        {"id":"526","bolge":"MARMARA","il":"KIRKLARELİ","ilce":"VİZE","plaka":"39","nviid":"1714"},
        {"id":"527","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"AKÇAKENT","plaka":"40","nviid":"1869"},
        {"id":"528","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"AKPINAR","plaka":"40","nviid":"1754"},
        {"id":"529","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"BOZTEPE","plaka":"40","nviid":"1890"},
        {"id":"530","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"ÇİÇEKDAĞI","plaka":"40","nviid":"1254"},
        {"id":"531","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"KAMAN","plaka":"40","nviid":"1429"},
        {"id":"532","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"MERKEZ","plaka":"40","nviid":"1472"},
        {"id":"533","bolge":"İÇ ANADOLU","il":"KIRŞEHİR","ilce":"MUCUR","plaka":"40","nviid":"1529"},
        {"id":"534","bolge":"GÜNEYDOĞU ANADOLU","il":"KİLİS","ilce":"MERKEZ","plaka":"79","nviid":"1476"},
        {"id":"535","bolge":"MARMARA","il":"KOCAELİ","ilce":"BAŞİSKELE","plaka":"41","nviid":"2058"},
        {"id":"536","bolge":"MARMARA","il":"KOCAELİ","ilce":"ÇAYIROVA","plaka":"41","nviid":"2059"},
        {"id":"537","bolge":"MARMARA","il":"KOCAELİ","ilce":"DARICA","plaka":"41","nviid":"2060"},
        {"id":"538","bolge":"MARMARA","il":"KOCAELİ","ilce":"DERİNCE","plaka":"41","nviid":"2030"},
        {"id":"539","bolge":"MARMARA","il":"KOCAELİ","ilce":"DİLOVASI","plaka":"41","nviid":"2061"},
        {"id":"540","bolge":"MARMARA","il":"KOCAELİ","ilce":"GEBZE","plaka":"41","nviid":"1338"},
        {"id":"541","bolge":"MARMARA","il":"KOCAELİ","ilce":"GÖLCÜK","plaka":"41","nviid":"1355"},
        {"id":"542","bolge":"MARMARA","il":"KOCAELİ","ilce":"İZMİT","plaka":"41","nviid":"2062"},
        {"id":"543","bolge":"MARMARA","il":"KOCAELİ","ilce":"KANDIRA","plaka":"41","nviid":"1430"},
        {"id":"544","bolge":"MARMARA","il":"KOCAELİ","ilce":"KARAMÜRSEL","plaka":"41","nviid":"1440"},
        {"id":"545","bolge":"MARMARA","il":"KOCAELİ","ilce":"KARTEPE","plaka":"41","nviid":"2063"},
        {"id":"546","bolge":"MARMARA","il":"KOCAELİ","ilce":"KÖRFEZ","plaka":"41","nviid":"1821"},
        {"id":"547","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"AHIRLI","plaka":"42","nviid":"1868"},
        {"id":"548","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"AKÖREN","plaka":"42","nviid":"1753"},
        {"id":"549","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"AKŞEHİR","plaka":"42","nviid":"1122"},
        {"id":"550","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"ALTINEKİN","plaka":"42","nviid":"1760"},
        {"id":"551","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"BEYŞEHİR","plaka":"42","nviid":"1188"},
        {"id":"552","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"BOZKIR","plaka":"42","nviid":"1207"},
        {"id":"553","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"CİHANBEYLİ","plaka":"42","nviid":"1222"},
        {"id":"554","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"ÇELTİK","plaka":"42","nviid":"1902"},
        {"id":"555","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"ÇUMRA","plaka":"42","nviid":"1262"},
        {"id":"556","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"DERBENT","plaka":"42","nviid":"1907"},
        {"id":"557","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"DEREBUCAK","plaka":"42","nviid":"1789"},
        {"id":"558","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"DOĞANHİSAR","plaka":"42","nviid":"1285"},
        {"id":"559","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"EMİRGAZİ","plaka":"42","nviid":"1920"},
        {"id":"560","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"EREĞLİ","plaka":"42","nviid":"1312"},
        {"id":"561","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"GÜNEYSINIR","plaka":"42","nviid":"1933"},
        {"id":"562","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"HADİM","plaka":"42","nviid":"1375"},
        {"id":"563","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"HALKAPINAR","plaka":"42","nviid":"1937"},
        {"id":"564","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"HÜYÜK","plaka":"42","nviid":"1804"},
        {"id":"565","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"ILGIN","plaka":"42","nviid":"1400"},
        {"id":"566","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"KADINHANI","plaka":"42","nviid":"1422"},
        {"id":"567","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"KARAPINAR","plaka":"42","nviid":"1441"},
        {"id":"568","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"KARATAY","plaka":"42","nviid":"1814"},
        {"id":"569","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"KULU","plaka":"42","nviid":"1491"},
        {"id":"570","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"MERAM","plaka":"42","nviid":"1827"},
        {"id":"571","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"SARAYÖNÜ","plaka":"42","nviid":"1598"},
        {"id":"572","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"SELÇUKLU","plaka":"42","nviid":"1839"},
        {"id":"573","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"SEYDİŞEHİR","plaka":"42","nviid":"1617"},
        {"id":"574","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"TAŞKENT","plaka":"42","nviid":"1848"},
        {"id":"575","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"TUZLUKÇU","plaka":"42","nviid":"1990"},
        {"id":"576","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"YALIHÜYÜK","plaka":"42","nviid":"1994"},
        {"id":"577","bolge":"İÇ ANADOLU","il":"KONYA","ilce":"YUNAK","plaka":"42","nviid":"1735"},
        {"id":"578","bolge":"EGE","il":"KÜTAHYA","ilce":"ALTINTAŞ","plaka":"43","nviid":"1132"},
        {"id":"579","bolge":"EGE","il":"KÜTAHYA","ilce":"ASLANAPA","plaka":"43","nviid":"1764"},
        {"id":"580","bolge":"EGE","il":"KÜTAHYA","ilce":"ÇAVDARHİSAR","plaka":"43","nviid":"1898"},
        {"id":"581","bolge":"EGE","il":"KÜTAHYA","ilce":"DOMANİÇ","plaka":"43","nviid":"1288"},
        {"id":"582","bolge":"EGE","il":"KÜTAHYA","ilce":"DUMLUPINAR","plaka":"43","nviid":"1790"},
        {"id":"583","bolge":"EGE","il":"KÜTAHYA","ilce":"EMET","plaka":"43","nviid":"1304"},
        {"id":"584","bolge":"EGE","il":"KÜTAHYA","ilce":"GEDİZ","plaka":"43","nviid":"1339"},
        {"id":"585","bolge":"EGE","il":"KÜTAHYA","ilce":"HİSARCIK","plaka":"43","nviid":"1802"},
        {"id":"586","bolge":"EGE","il":"KÜTAHYA","ilce":"MERKEZ","plaka":"43","nviid":"1500"},
        {"id":"587","bolge":"EGE","il":"KÜTAHYA","ilce":"PAZARLAR","plaka":"43","nviid":"1979"},
        {"id":"588","bolge":"EGE","il":"KÜTAHYA","ilce":"SİMAV","plaka":"43","nviid":"1625"},
        {"id":"589","bolge":"EGE","il":"KÜTAHYA","ilce":"ŞAPHANE","plaka":"43","nviid":"1843"},
        {"id":"590","bolge":"EGE","il":"KÜTAHYA","ilce":"TAVŞANLI","plaka":"43","nviid":"1671"},
        {"id":"591","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"AKÇADAĞ","plaka":"44","nviid":"1114"},
        {"id":"592","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"ARAPGİR","plaka":"44","nviid":"1143"},
        {"id":"593","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"ARGUVAN","plaka":"44","nviid":"1148"},
        {"id":"594","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"BATTALGAZİ","plaka":"44","nviid":"1772"},
        {"id":"595","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"DARENDE","plaka":"44","nviid":"1265"},
        {"id":"596","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"DOĞANŞEHİR","plaka":"44","nviid":"1286"},
        {"id":"597","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"DOĞANYOL","plaka":"44","nviid":"1914"},
        {"id":"598","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"HEKİMHAN","plaka":"44","nviid":"1390"},
        {"id":"599","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"AFŞİN","plaka":"46","nviid":"1107"},
        {"id":"600","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"ANDIRIN","plaka":"46","nviid":"1136"},
        {"id":"601","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"ÇAĞLAYANCERİT","plaka":"46","nviid":"1785"},
        {"id":"602","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"EKİNÖZÜ","plaka":"46","nviid":"1919"},
        {"id":"603","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"ELBİSTAN","plaka":"46","nviid":"1299"},
        {"id":"604","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"GÖKSUN","plaka":"46","nviid":"1353"},
        {"id":"605","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"NURHAK","plaka":"46","nviid":"1975"},
        {"id":"606","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"PAZARCIK","plaka":"46","nviid":"1570"},
        {"id":"607","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"TÜRKOĞLU","plaka":"46","nviid":"1694"},
        {"id":"608","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"KALE","plaka":"44","nviid":"1953"},
        {"id":"609","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"KULUNCAK","plaka":"44","nviid":"1969"},
        {"id":"610","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"PÜTÜRGE","plaka":"44","nviid":"1582"},
        {"id":"611","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"YAZIHAN","plaka":"44","nviid":"1995"},
        {"id":"612","bolge":"DOĞU ANADOLU","il":"MALATYA","ilce":"YEŞİLYURT","plaka":"44","nviid":"1729"},
        {"id":"613","bolge":"EGE","il":"MANİSA","ilce":"AHMETLİ","plaka":"45","nviid":"1751"},
        {"id":"614","bolge":"EGE","il":"MANİSA","ilce":"AKHİSAR","plaka":"45","nviid":"1118"},
        {"id":"615","bolge":"EGE","il":"MANİSA","ilce":"ALAŞEHİR","plaka":"45","nviid":"1127"},
        {"id":"616","bolge":"EGE","il":"MANİSA","ilce":"DEMİRCİ","plaka":"45","nviid":"1269"},
        {"id":"617","bolge":"EGE","il":"MANİSA","ilce":"GÖLMARMARA","plaka":"45","nviid":"1793"},
        {"id":"618","bolge":"EGE","il":"MANİSA","ilce":"GÖRDES","plaka":"45","nviid":"1362"},
        {"id":"619","bolge":"EGE","il":"MANİSA","ilce":"KIRKAĞAÇ","plaka":"45","nviid":"1470"},
        {"id":"620","bolge":"EGE","il":"MANİSA","ilce":"KÖPRÜBAŞI","plaka":"45","nviid":"1965"},
        {"id":"621","bolge":"EGE","il":"MANİSA","ilce":"KULA","plaka":"45","nviid":"1489"},
        {"id":"622","bolge":"EGE","il":"MANİSA","ilce":"SALİHLİ","plaka":"45","nviid":"1590"},
        {"id":"623","bolge":"EGE","il":"MANİSA","ilce":"SARIGÖL","plaka":"45","nviid":"1600"},
        {"id":"624","bolge":"EGE","il":"MANİSA","ilce":"SARUHANLI","plaka":"45","nviid":"1606"},
        {"id":"625","bolge":"EGE","il":"MANİSA","ilce":"SELENDİ","plaka":"45","nviid":"1613"},
        {"id":"626","bolge":"EGE","il":"MANİSA","ilce":"SOMA","plaka":"45","nviid":"1634"},
        {"id":"627","bolge":"EGE","il":"MANİSA","ilce":"TURGUTLU","plaka":"45","nviid":"1689"},
        {"id":"628","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"DARGEÇİT","plaka":"47","nviid":"1787"},
        {"id":"629","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"DERİK","plaka":"47","nviid":"1273"},
        {"id":"630","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"KIZILTEPE","plaka":"47","nviid":"1474"},
        {"id":"631","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"MAZIDAĞI","plaka":"47","nviid":"1519"},
        {"id":"632","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"MİDYAT","plaka":"47","nviid":"1526"},
        {"id":"633","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"NUSAYBİN","plaka":"47","nviid":"1547"},
        {"id":"634","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"ÖMERLİ","plaka":"47","nviid":"1564"},
        {"id":"635","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"SAVUR","plaka":"47","nviid":"1609"},
        {"id":"636","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"YEŞİLLİ","plaka":"47","nviid":"2002"},
        {"id":"637","bolge":"AKDENİZ","il":"MERSİN","ilce":"AKDENİZ","plaka":"33","nviid":"2064"},
        {"id":"638","bolge":"AKDENİZ","il":"MERSİN","ilce":"ANAMUR","plaka":"33","nviid":"1135"},
        {"id":"639","bolge":"AKDENİZ","il":"MERSİN","ilce":"AYDINCIK","plaka":"33","nviid":"1766"},
        {"id":"640","bolge":"AKDENİZ","il":"MERSİN","ilce":"BOZYAZI","plaka":"33","nviid":"1779"},
        {"id":"641","bolge":"AKDENİZ","il":"MERSİN","ilce":"ÇAMLIYAYLA","plaka":"33","nviid":"1892"},
        {"id":"642","bolge":"AKDENİZ","il":"MERSİN","ilce":"ERDEMLİ","plaka":"33","nviid":"1311"},
        {"id":"643","bolge":"AKDENİZ","il":"MERSİN","ilce":"GÜLNAR","plaka":"33","nviid":"1366"},
        {"id":"644","bolge":"AKDENİZ","il":"MERSİN","ilce":"MEZİTLİ","plaka":"33","nviid":"2065"},
        {"id":"645","bolge":"AKDENİZ","il":"MERSİN","ilce":"MUT","plaka":"33","nviid":"1536"},
        {"id":"646","bolge":"AKDENİZ","il":"MERSİN","ilce":"SİLİFKE","plaka":"33","nviid":"1621"},
        {"id":"647","bolge":"AKDENİZ","il":"MERSİN","ilce":"TARSUS","plaka":"33","nviid":"1665"},
        {"id":"648","bolge":"AKDENİZ","il":"MERSİN","ilce":"TOROSLAR","plaka":"33","nviid":"2066"},
        {"id":"649","bolge":"AKDENİZ","il":"MERSİN","ilce":"YENİŞEHİR","plaka":"33","nviid":"2067"},
        {"id":"650","bolge":"EGE","il":"MUĞLA","ilce":"BODRUM","plaka":"48","nviid":"1197"},
        {"id":"651","bolge":"EGE","il":"MUĞLA","ilce":"DALAMAN","plaka":"48","nviid":"1742"},
        {"id":"652","bolge":"EGE","il":"MUĞLA","ilce":"DATÇA","plaka":"48","nviid":"1266"},
        {"id":"653","bolge":"EGE","il":"MUĞLA","ilce":"FETHİYE","plaka":"48","nviid":"1331"},
        {"id":"654","bolge":"EGE","il":"MUĞLA","ilce":"KAVAKLIDERE","plaka":"48","nviid":"1958"},
        {"id":"655","bolge":"EGE","il":"MUĞLA","ilce":"KÖYCEĞİZ","plaka":"48","nviid":"1488"},
        {"id":"656","bolge":"EGE","il":"MUĞLA","ilce":"MARMARİS","plaka":"48","nviid":"1517"},
        {"id":"657","bolge":"EGE","il":"MUĞLA","ilce":"MİLAS","plaka":"48","nviid":"1528"},
        {"id":"658","bolge":"EGE","il":"MUĞLA","ilce":"ORTACA","plaka":"48","nviid":"1831"},
        {"id":"659","bolge":"EGE","il":"MUĞLA","ilce":"ULA","plaka":"48","nviid":"1695"},
        {"id":"660","bolge":"EGE","il":"MUĞLA","ilce":"YATAĞAN","plaka":"48","nviid":"1719"},
        {"id":"661","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"BULANIK","plaka":"49","nviid":"1213"},
        {"id":"662","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"HASKÖY","plaka":"49","nviid":"1801"},
        {"id":"663","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"KORKUT","plaka":"49","nviid":"1964"},
        {"id":"664","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"MALAZGİRT","plaka":"49","nviid":"1510"},
        {"id":"665","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"MERKEZ","plaka":"49","nviid":"1534"},
        {"id":"666","bolge":"DOĞU ANADOLU","il":"MUŞ","ilce":"VARTO","plaka":"49","nviid":"1711"},
        {"id":"667","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"ACIGÖL","plaka":"50","nviid":"1749"},
        {"id":"668","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"AVANOS","plaka":"50","nviid":"1155"},
        {"id":"669","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"DERİNKUYU","plaka":"50","nviid":"1274"},
        {"id":"670","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"GÜLŞEHİR","plaka":"50","nviid":"1367"},
        {"id":"671","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"HACIBEKTAŞ","plaka":"50","nviid":"1374"},
        {"id":"672","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"KOZAKLI","plaka":"50","nviid":"1485"},
        {"id":"673","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"MERKEZ","plaka":"50","nviid":"1543"},
        {"id":"674","bolge":"İÇ ANADOLU","il":"NEVŞEHİR","ilce":"ÜRGÜP","plaka":"50","nviid":"1707"},
        {"id":"675","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"ALTUNHİSAR","plaka":"51","nviid":"1876"},
        {"id":"676","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"BOR","plaka":"51","nviid":"1201"},
        {"id":"677","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"ÇAMARDI","plaka":"51","nviid":"1225"},
        {"id":"678","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"ÇİFTLİK","plaka":"51","nviid":"1904"},
        {"id":"679","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"MERKEZ","plaka":"51","nviid":"1544"},
        {"id":"680","bolge":"İÇ ANADOLU","il":"NİĞDE","ilce":"ULUKIŞLA","plaka":"51","nviid":"1700"},
        {"id":"681","bolge":"KARADENİZ","il":"ORDU","ilce":"AKKUŞ","plaka":"52","nviid":"1119"},
        {"id":"682","bolge":"KARADENİZ","il":"ORDU","ilce":"AYBASTI","plaka":"52","nviid":"1158"},
        {"id":"683","bolge":"KARADENİZ","il":"ORDU","ilce":"ÇAMAŞ","plaka":"52","nviid":"1891"},
        {"id":"684","bolge":"KARADENİZ","il":"ORDU","ilce":"ÇATALPINAR","plaka":"52","nviid":"1897"},
        {"id":"685","bolge":"KARADENİZ","il":"ORDU","ilce":"ÇAYBAŞI","plaka":"52","nviid":"1900"},
        {"id":"686","bolge":"KARADENİZ","il":"ORDU","ilce":"FATSA","plaka":"52","nviid":"1328"},
        {"id":"687","bolge":"KARADENİZ","il":"ORDU","ilce":"GÖLKÖY","plaka":"52","nviid":"1358"},
        {"id":"688","bolge":"KARADENİZ","il":"ORDU","ilce":"GÜLYALI","plaka":"52","nviid":"1795"},
        {"id":"689","bolge":"KARADENİZ","il":"ORDU","ilce":"GÜRGENTEPE","plaka":"52","nviid":"1797"},
        {"id":"690","bolge":"KARADENİZ","il":"ORDU","ilce":"İKİZCE","plaka":"52","nviid":"1947"},
        {"id":"691","bolge":"KARADENİZ","il":"ORDU","ilce":"KABADÜZ","plaka":"52","nviid":"1950"},
        {"id":"692","bolge":"KARADENİZ","il":"ORDU","ilce":"KABATAŞ","plaka":"52","nviid":"1951"},
        {"id":"693","bolge":"KARADENİZ","il":"ORDU","ilce":"KORGAN","plaka":"52","nviid":"1482"},
        {"id":"694","bolge":"KARADENİZ","il":"ORDU","ilce":"KUMRU","plaka":"52","nviid":"1493"},
        {"id":"695","bolge":"KARADENİZ","il":"ORDU","ilce":"MESUDİYE","plaka":"52","nviid":"1525"},
        {"id":"696","bolge":"KARADENİZ","il":"ORDU","ilce":"PERŞEMBE","plaka":"52","nviid":"1573"},
        {"id":"697","bolge":"KARADENİZ","il":"ORDU","ilce":"ULUBEY","plaka":"52","nviid":"1696"},
        {"id":"698","bolge":"KARADENİZ","il":"ORDU","ilce":"ÜNYE","plaka":"52","nviid":"1706"},
        {"id":"699","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"MERKEZ","plaka":"80","nviid":"1560"},
        {"id":"700","bolge":"KARADENİZ","il":"RİZE","ilce":"ARDEŞEN","plaka":"53","nviid":"1146"},
        {"id":"701","bolge":"KARADENİZ","il":"RİZE","ilce":"ÇAMLIHEMŞİN","plaka":"53","nviid":"1228"},
        {"id":"702","bolge":"KARADENİZ","il":"RİZE","ilce":"ÇAYELİ","plaka":"53","nviid":"1241"},
        {"id":"703","bolge":"KARADENİZ","il":"RİZE","ilce":"DEREPAZARI","plaka":"53","nviid":"1908"},
        {"id":"704","bolge":"KARADENİZ","il":"RİZE","ilce":"FINDIKLI","plaka":"53","nviid":"1332"},
        {"id":"705","bolge":"KARADENİZ","il":"RİZE","ilce":"GÜNEYSU","plaka":"53","nviid":"1796"},
        {"id":"706","bolge":"KARADENİZ","il":"RİZE","ilce":"HEMŞİN","plaka":"53","nviid":"1943"},
        {"id":"707","bolge":"KARADENİZ","il":"RİZE","ilce":"İKİZDERE","plaka":"53","nviid":"1405"},
        {"id":"708","bolge":"KARADENİZ","il":"RİZE","ilce":"İYİDERE","plaka":"53","nviid":"1949"},
        {"id":"709","bolge":"KARADENİZ","il":"RİZE","ilce":"KALKANDERE","plaka":"53","nviid":"1428"},
        {"id":"710","bolge":"KARADENİZ","il":"RİZE","ilce":"MERKEZ","plaka":"53","nviid":"1586"},
        {"id":"711","bolge":"KARADENİZ","il":"RİZE","ilce":"PAZAR","plaka":"53","nviid":"1569"},
        {"id":"712","bolge":"MARMARA","il":"SAKARYA","ilce":"ADAPAZARI","plaka":"54","nviid":"2068"},
        {"id":"713","bolge":"MARMARA","il":"SAKARYA","ilce":"AKYAZI","plaka":"54","nviid":"1123"},
        {"id":"714","bolge":"MARMARA","il":"SAKARYA","ilce":"ARİFİYE","plaka":"54","nviid":"2069"},
        {"id":"715","bolge":"MARMARA","il":"SAKARYA","ilce":"ERENLER","plaka":"54","nviid":"2070"},
        {"id":"716","bolge":"MARMARA","il":"SAKARYA","ilce":"FERİZLİ","plaka":"54","nviid":"1925"},
        {"id":"717","bolge":"MARMARA","il":"SAKARYA","ilce":"GEYVE","plaka":"54","nviid":"1351"},
        {"id":"718","bolge":"MARMARA","il":"SAKARYA","ilce":"HENDEK","plaka":"54","nviid":"1391"},
        {"id":"719","bolge":"MARMARA","il":"SAKARYA","ilce":"KARAPÜRÇEK","plaka":"54","nviid":"1955"},
        {"id":"720","bolge":"MARMARA","il":"SAKARYA","ilce":"KARASU","plaka":"54","nviid":"1442"},
        {"id":"721","bolge":"MARMARA","il":"SAKARYA","ilce":"KAYNARCA","plaka":"54","nviid":"1453"},
        {"id":"722","bolge":"MARMARA","il":"SAKARYA","ilce":"KOCAALİ","plaka":"54","nviid":"1818"},
        {"id":"723","bolge":"MARMARA","il":"SAKARYA","ilce":"PAMUKOVA","plaka":"54","nviid":"1833"},
        {"id":"724","bolge":"MARMARA","il":"SAKARYA","ilce":"SAPANCA","plaka":"54","nviid":"1595"},
        {"id":"725","bolge":"MARMARA","il":"SAKARYA","ilce":"SERDİVAN","plaka":"54","nviid":"2071"},
        {"id":"726","bolge":"MARMARA","il":"SAKARYA","ilce":"SÖĞÜTLÜ","plaka":"54","nviid":"1986"},
        {"id":"727","bolge":"MARMARA","il":"SAKARYA","ilce":"TARAKLI","plaka":"54","nviid":"1847"},
        {"id":"728","bolge":"KARADENİZ","il":"SAMSUN","ilce":"ALAÇAM","plaka":"55","nviid":"1125"},
        {"id":"729","bolge":"KARADENİZ","il":"SAMSUN","ilce":"ASARCIK","plaka":"55","nviid":"1763"},
        {"id":"730","bolge":"KARADENİZ","il":"SAMSUN","ilce":"ATAKUM","plaka":"55","nviid":"2072"},
        {"id":"731","bolge":"KARADENİZ","il":"SAMSUN","ilce":"AYVACIK","plaka":"55","nviid":"1879"},
        {"id":"732","bolge":"KARADENİZ","il":"SAMSUN","ilce":"BAFRA","plaka":"55","nviid":"1164"},
        {"id":"733","bolge":"KARADENİZ","il":"SAMSUN","ilce":"CANİK","plaka":"55","nviid":"2073"},
        {"id":"734","bolge":"KARADENİZ","il":"SAMSUN","ilce":"ÇARŞAMBA","plaka":"55","nviid":"1234"},
        {"id":"735","bolge":"KARADENİZ","il":"SAMSUN","ilce":"HAVZA","plaka":"55","nviid":"1386"},
        {"id":"736","bolge":"KARADENİZ","il":"SAMSUN","ilce":"İLKADIM","plaka":"55","nviid":"2074"},
        {"id":"737","bolge":"KARADENİZ","il":"SAMSUN","ilce":"KAVAK","plaka":"55","nviid":"1452"},
        {"id":"738","bolge":"KARADENİZ","il":"SAMSUN","ilce":"LADİK","plaka":"55","nviid":"1501"},
        {"id":"739","bolge":"KARADENİZ","il":"SAMSUN","ilce":"ONDOKUZMAYIS","plaka":"55","nviid":"1830"},
        {"id":"740","bolge":"KARADENİZ","il":"SAMSUN","ilce":"SALIPAZARI","plaka":"55","nviid":"1838"},
        {"id":"741","bolge":"KARADENİZ","il":"SAMSUN","ilce":"TEKKEKÖY","plaka":"55","nviid":"1849"},
        {"id":"742","bolge":"KARADENİZ","il":"SAMSUN","ilce":"TERME","plaka":"55","nviid":"1676"},
        {"id":"743","bolge":"KARADENİZ","il":"SAMSUN","ilce":"VEZİRKÖPRÜ","plaka":"55","nviid":"1712"},
        {"id":"744","bolge":"KARADENİZ","il":"SAMSUN","ilce":"YAKAKENT","plaka":"55","nviid":"1993"},
        {"id":"745","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"AYDINLAR","plaka":"56","nviid":"1878"},
        {"id":"746","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"BAYKAN","plaka":"56","nviid":"1179"},
        {"id":"747","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"ERUH","plaka":"56","nviid":"1317"},
        {"id":"748","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"KURTALAN","plaka":"56","nviid":"1495"},
        {"id":"749","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"MERKEZ","plaka":"56","nviid":"1620"},
        {"id":"750","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"PERVARİ","plaka":"56","nviid":"1575"},
        {"id":"751","bolge":"GÜNEYDOĞU ANADOLU","il":"SİİRT","ilce":"ŞİRVAN","plaka":"56","nviid":"1662"},
        {"id":"752","bolge":"KARADENİZ","il":"SİNOP","ilce":"AYANCIK","plaka":"57","nviid":"1156"},
        {"id":"753","bolge":"KARADENİZ","il":"SİNOP","ilce":"BOYABAT","plaka":"57","nviid":"1204"},
        {"id":"754","bolge":"KARADENİZ","il":"SİNOP","ilce":"DİKMEN","plaka":"57","nviid":"1910"},
        {"id":"755","bolge":"KARADENİZ","il":"SİNOP","ilce":"DURAĞAN","plaka":"57","nviid":"1290"},
        {"id":"756","bolge":"KARADENİZ","il":"SİNOP","ilce":"ERFELEK","plaka":"57","nviid":"1314"},
        {"id":"757","bolge":"KARADENİZ","il":"SİNOP","ilce":"GERZE","plaka":"57","nviid":"1349"},
        {"id":"758","bolge":"KARADENİZ","il":"SİNOP","ilce":"MERKEZ","plaka":"57","nviid":"1627"},
        {"id":"759","bolge":"KARADENİZ","il":"SİNOP","ilce":"SARAYDÜZÜ","plaka":"57","nviid":"1981"},
        {"id":"760","bolge":"KARADENİZ","il":"SİNOP","ilce":"TÜRKELİ","plaka":"57","nviid":"1693"},
        {"id":"761","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"AKINCILAR","plaka":"58","nviid":"1870"},
        {"id":"762","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"ALTINYAYLA","plaka":"58","nviid":"1875"},
        {"id":"763","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"DİVRİĞİ","plaka":"58","nviid":"1282"},
        {"id":"764","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"DOĞANŞAR","plaka":"58","nviid":"1913"},
        {"id":"765","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"GEMEREK","plaka":"58","nviid":"1342"},
        {"id":"766","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"GÖLOVA","plaka":"58","nviid":"1927"},
        {"id":"767","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"GÜRÜN","plaka":"58","nviid":"1373"},
        {"id":"768","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"HAFİK","plaka":"58","nviid":"1376"},
        {"id":"769","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"İMRANLI","plaka":"58","nviid":"1407"},
        {"id":"770","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"KANGAL","plaka":"58","nviid":"1431"},
        {"id":"771","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"KOYULHİSAR","plaka":"58","nviid":"1484"},
        {"id":"772","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"MERKEZ","plaka":"58","nviid":"1628"},
        {"id":"773","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"SUŞEHRİ","plaka":"58","nviid":"1646"},
        {"id":"774","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"ŞARKIŞLA","plaka":"58","nviid":"1650"},
        {"id":"775","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"ULAŞ","plaka":"58","nviid":"1991"},
        {"id":"776","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"YILDIZELİ","plaka":"58","nviid":"1731"},
        {"id":"777","bolge":"İÇ ANADOLU","il":"SİVAS","ilce":"ZARA","plaka":"58","nviid":"1738"},
        {"id":"778","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"MERKEZ","plaka":"73","nviid":"1661"},
        {"id":"779","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"ÇERKEZKÖY","plaka":"59","nviid":"1250"},
        {"id":"780","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"AKÇAKALE","plaka":"63","nviid":"1115"},
        {"id":"781","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"BİRECİK","plaka":"63","nviid":"1194"},
        {"id":"782","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"BOZOVA","plaka":"63","nviid":"1209"},
        {"id":"783","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"CEYLANPINAR","plaka":"63","nviid":"1220"},
        {"id":"784","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"HALFETİ","plaka":"63","nviid":"1378"},
        {"id":"785","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"HARRAN","plaka":"63","nviid":"1800"},
        {"id":"786","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"HİLVAN","plaka":"63","nviid":"1393"},
        {"id":"787","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"SİVEREK","plaka":"63","nviid":"1630"},
        {"id":"788","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"SURUÇ","plaka":"63","nviid":"1643"},
        {"id":"789","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"VİRANŞEHİR","plaka":"63","nviid":"1713"},
        {"id":"790","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"ÇORLU","plaka":"59","nviid":"1258"},
        {"id":"791","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"HAYRABOLU","plaka":"59","nviid":"1388"},
        {"id":"792","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"MALKARA","plaka":"59","nviid":"1511"},
        {"id":"793","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"MARMARAEREĞLİSİ","plaka":"59","nviid":"1825"},
        {"id":"794","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"MURATLI","plaka":"59","nviid":"1538"},
        {"id":"795","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"SARAY","plaka":"59","nviid":"1596"},
        {"id":"796","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"ŞARKÖY","plaka":"59","nviid":"1652"},
        {"id":"797","bolge":"KARADENİZ","il":"TOKAT","ilce":"ALMUS","plaka":"60","nviid":"1129"},
        {"id":"798","bolge":"KARADENİZ","il":"TOKAT","ilce":"ARTOVA","plaka":"60","nviid":"1151"},
        {"id":"799","bolge":"KARADENİZ","il":"TOKAT","ilce":"BAŞÇİFTLİK","plaka":"60","nviid":"1883"},
        {"id":"800","bolge":"KARADENİZ","il":"TOKAT","ilce":"ERBAA","plaka":"60","nviid":"1308"},
        {"id":"801","bolge":"KARADENİZ","il":"TOKAT","ilce":"MERKEZ","plaka":"60","nviid":"1679"},
        {"id":"802","bolge":"KARADENİZ","il":"TOKAT","ilce":"NİKSAR","plaka":"60","nviid":"1545"},
        {"id":"803","bolge":"KARADENİZ","il":"TOKAT","ilce":"PAZAR","plaka":"60","nviid":"1834"},
        {"id":"804","bolge":"KARADENİZ","il":"TOKAT","ilce":"REŞADİYE","plaka":"60","nviid":"1584"},
        {"id":"805","bolge":"KARADENİZ","il":"TOKAT","ilce":"SULUSARAY","plaka":"60","nviid":"1987"},
        {"id":"806","bolge":"KARADENİZ","il":"TOKAT","ilce":"TURHAL","plaka":"60","nviid":"1690"},
        {"id":"807","bolge":"KARADENİZ","il":"TOKAT","ilce":"YEŞİLYURT","plaka":"60","nviid":"1858"},
        {"id":"808","bolge":"KARADENİZ","il":"TOKAT","ilce":"ZİLE","plaka":"60","nviid":"1740"},
        {"id":"809","bolge":"KARADENİZ","il":"TRABZON","ilce":"AKÇAABAT","plaka":"61","nviid":"1113"},
        {"id":"810","bolge":"KARADENİZ","il":"TRABZON","ilce":"ARAKLI","plaka":"61","nviid":"1141"},
        {"id":"811","bolge":"KARADENİZ","il":"TRABZON","ilce":"ARSİN","plaka":"61","nviid":"1150"},
        {"id":"812","bolge":"KARADENİZ","il":"TRABZON","ilce":"BEŞİKDÜZÜ","plaka":"61","nviid":"1775"},
        {"id":"813","bolge":"KARADENİZ","il":"TRABZON","ilce":"ÇARŞIBAŞI","plaka":"61","nviid":"1896"},
        {"id":"814","bolge":"KARADENİZ","il":"TRABZON","ilce":"ÇAYKARA","plaka":"61","nviid":"1244"},
        {"id":"815","bolge":"KARADENİZ","il":"TRABZON","ilce":"DERNEKPAZARI","plaka":"61","nviid":"1909"},
        {"id":"816","bolge":"KARADENİZ","il":"TRABZON","ilce":"DÜZKÖY","plaka":"61","nviid":"1917"},
        {"id":"817","bolge":"KARADENİZ","il":"TRABZON","ilce":"HAYRAT","plaka":"61","nviid":"1942"},
        {"id":"818","bolge":"KARADENİZ","il":"TRABZON","ilce":"KÖPRÜBAŞI","plaka":"61","nviid":"1966"},
        {"id":"819","bolge":"KARADENİZ","il":"TRABZON","ilce":"MAÇKA","plaka":"61","nviid":"1507"},
        {"id":"820","bolge":"KARADENİZ","il":"TRABZON","ilce":"OF","plaka":"61","nviid":"1548"},
        {"id":"821","bolge":"KARADENİZ","il":"TRABZON","ilce":"SÜRMENE","plaka":"61","nviid":"1647"},
        {"id":"822","bolge":"KARADENİZ","il":"TRABZON","ilce":"ŞALPAZARI","plaka":"61","nviid":"1842"},
        {"id":"823","bolge":"KARADENİZ","il":"TRABZON","ilce":"TONYA","plaka":"61","nviid":"1681"},
        {"id":"824","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"AĞAÇÖREN","plaka":"68","nviid":"1860"},
        {"id":"825","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"ESKİL","plaka":"68","nviid":"1921"},
        {"id":"826","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"GÜLAĞAÇ","plaka":"68","nviid":"1932"},
        {"id":"827","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"GÜZELYURT","plaka":"68","nviid":"1861"},
        {"id":"828","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"ORTAKÖY","plaka":"68","nviid":"1557"},
        {"id":"829","bolge":"İÇ ANADOLU","il":"AKSARAY","ilce":"SARIYAHŞİ","plaka":"68","nviid":"1866"},
        {"id":"830","bolge":"KARADENİZ","il":"BAYBURT","ilce":"AYDINTEPE","plaka":"69","nviid":"1767"},
        {"id":"831","bolge":"KARADENİZ","il":"BAYBURT","ilce":"DEMİRÖZÜ","plaka":"69","nviid":"1788"},
        {"id":"832","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"AYRANCI","plaka":"70","nviid":"1768"},
        {"id":"833","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"BAŞYAYLA","plaka":"70","nviid":"1884"},
        {"id":"834","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"ERMENEK","plaka":"70","nviid":"1316"},
        {"id":"835","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"KAZIMKARABEKİR","plaka":"70","nviid":"1862"},
        {"id":"836","bolge":"İÇ ANADOLU","il":"KARAMAN","ilce":"SARIVELİLER","plaka":"70","nviid":"1983"},
        {"id":"837","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"BAHŞİLİ","plaka":"71","nviid":"1880"},
        {"id":"838","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"BALIŞEYH","plaka":"71","nviid":"1882"},
        {"id":"839","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"ÇELEBİ","plaka":"71","nviid":"1901"},
        {"id":"840","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"DELİCE","plaka":"71","nviid":"1268"},
        {"id":"841","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"KARAKEÇİLİ","plaka":"71","nviid":"1954"},
        {"id":"842","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"KESKİN","plaka":"71","nviid":"1463"},
        {"id":"843","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"SULAKYURT","plaka":"71","nviid":"1638"},
        {"id":"844","bolge":"İÇ ANADOLU","il":"KIRIKKALE","ilce":"YAHŞİHAN","plaka":"71","nviid":"1992"},
        {"id":"845","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"BEŞİRİ","plaka":"72","nviid":"1184"},
        {"id":"846","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"GERCÜŞ","plaka":"72","nviid":"1345"},
        {"id":"847","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"HASANKEYF","plaka":"72","nviid":"1941"},
        {"id":"848","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"KOZLUK","plaka":"72","nviid":"1487"},
        {"id":"849","bolge":"GÜNEYDOĞU ANADOLU","il":"BATMAN","ilce":"SASON","plaka":"72","nviid":"1607"},
        {"id":"850","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"BEYTÜŞŞEBAP","plaka":"73","nviid":"1189"},
        {"id":"851","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"CİZRE","plaka":"73","nviid":"1223"},
        {"id":"852","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"GÜÇLÜKONAK","plaka":"73","nviid":"1931"},
        {"id":"853","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"İDİL","plaka":"73","nviid":"1403"},
        {"id":"854","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"SİLOPİ","plaka":"73","nviid":"1623"},
        {"id":"855","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞIRNAK","ilce":"ULUDERE","plaka":"73","nviid":"1698"},
        {"id":"856","bolge":"KARADENİZ","il":"BARTIN","ilce":"AMASRA","plaka":"74","nviid":"1761"},
        {"id":"857","bolge":"KARADENİZ","il":"BARTIN","ilce":"KURUCAŞİLE","plaka":"74","nviid":"1496"},
        {"id":"858","bolge":"KARADENİZ","il":"BARTIN","ilce":"ULUS","plaka":"74","nviid":"1701"},
        {"id":"859","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"ÇILDIR","plaka":"75","nviid":"1252"},
        {"id":"860","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"DAMAL","plaka":"75","nviid":"2008"},
        {"id":"861","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"GÖLE","plaka":"75","nviid":"1356"},
        {"id":"862","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"HANAK","plaka":"75","nviid":"1380"},
        {"id":"863","bolge":"DOĞU ANADOLU","il":"ARDAHAN","ilce":"POSOF","plaka":"75","nviid":"1579"},
        {"id":"864","bolge":"DOĞU ANADOLU","il":"IĞDIR","ilce":"ARALIK","plaka":"76","nviid":"1142"},
        {"id":"865","bolge":"DOĞU ANADOLU","il":"IĞDIR","ilce":"KARAKOYUNLU","plaka":"76","nviid":"2011"},
        {"id":"866","bolge":"DOĞU ANADOLU","il":"IĞDIR","ilce":"TUZLUCA","plaka":"76","nviid":"1692"},
        {"id":"867","bolge":"KARADENİZ","il":"TRABZON","ilce":"VAKFIKEBİR","plaka":"61","nviid":"1709"},
        {"id":"868","bolge":"KARADENİZ","il":"TRABZON","ilce":"YOMRA","plaka":"61","nviid":"1732"},
        {"id":"869","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"ÇEMİŞGEZEK","plaka":"62","nviid":"1247"},
        {"id":"870","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"HOZAT","plaka":"62","nviid":"1397"},
        {"id":"871","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"MAZGİRT","plaka":"62","nviid":"1518"},
        {"id":"872","bolge":"KARADENİZ","il":"KARABÜK","ilce":"EFLANİ","plaka":"78","nviid":"1296"},
        {"id":"873","bolge":"KARADENİZ","il":"KARABÜK","ilce":"ESKİPAZAR","plaka":"78","nviid":"1321"},
        {"id":"874","bolge":"KARADENİZ","il":"KARABÜK","ilce":"OVACIK","plaka":"78","nviid":"1561"},
        {"id":"875","bolge":"KARADENİZ","il":"KARABÜK","ilce":"SAFRANBOLU","plaka":"78","nviid":"1587"},
        {"id":"876","bolge":"KARADENİZ","il":"KARABÜK","ilce":"YENİCE","plaka":"78","nviid":"1856"},
        {"id":"877","bolge":"GÜNEYDOĞU ANADOLU","il":"KİLİS","ilce":"ELBEYLİ","plaka":"79","nviid":"2023"},
        {"id":"878","bolge":"GÜNEYDOĞU ANADOLU","il":"KİLİS","ilce":"MUSABEYLİ","plaka":"79","nviid":"2024"},
        {"id":"879","bolge":"GÜNEYDOĞU ANADOLU","il":"KİLİS","ilce":"POLATELİ","plaka":"79","nviid":"2025"},
        {"id":"880","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"BAHÇE","plaka":"80","nviid":"1165"},
        {"id":"881","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"DÜZİÇİ","plaka":"80","nviid":"1743"},
        {"id":"882","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"HASANBEYLİ","plaka":"80","nviid":"2027"},
        {"id":"883","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"KADİRLİ","plaka":"80","nviid":"1423"},
        {"id":"884","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"SUMBAS","plaka":"80","nviid":"2028"},
        {"id":"885","bolge":"AKDENİZ","il":"OSMANİYE","ilce":"TOPRAKKALE","plaka":"80","nviid":"2029"},
        {"id":"886","bolge":"KARADENİZ","il":"DÜZCE","ilce":"AKÇAKOCA","plaka":"81","nviid":"1116"},
        {"id":"887","bolge":"KARADENİZ","il":"DÜZCE","ilce":"CUMAYERİ","plaka":"81","nviid":"1784"},
        {"id":"888","bolge":"KARADENİZ","il":"DÜZCE","ilce":"ÇİLİMLİ","plaka":"81","nviid":"1905"},
        {"id":"889","bolge":"KARADENİZ","il":"DÜZCE","ilce":"GÖLYAKA","plaka":"81","nviid":"1794"},
        {"id":"890","bolge":"KARADENİZ","il":"DÜZCE","ilce":"GÜMÜŞOVA","plaka":"81","nviid":"2017"},
        {"id":"891","bolge":"KARADENİZ","il":"DÜZCE","ilce":"KAYNAŞLI","plaka":"81","nviid":"2031"},
        {"id":"892","bolge":"KARADENİZ","il":"DÜZCE","ilce":"YIĞILCA","plaka":"81","nviid":"1730"},
        {"id":"893","bolge":"EGE","il":"AYDIN","ilce":"EFELER","plaka":"9","nviid":"2076"},
        {"id":"894","bolge":"MARMARA","il":"BALIKESİR","ilce":"KARESİ","plaka":"10","nviid":"2078"},
        {"id":"895","bolge":"MARMARA","il":"BALIKESİR","ilce":"ALTIEYLÜL","plaka":"10","nviid":"2077"},
        {"id":"896","bolge":"EGE","il":"DENİZLİ","ilce":"MERKEZEFENDİ","plaka":"20","nviid":"2079"},
        {"id":"897","bolge":"AKDENİZ","il":"HATAY","ilce":"ANTAKYA","plaka":"31","nviid":"2080"},
        {"id":"898","bolge":"AKDENİZ","il":"HATAY","ilce":"DEFNE","plaka":"31","nviid":"2082"},
        {"id":"899","bolge":"AKDENİZ","il":"HATAY","ilce":"ARSUZ","plaka":"31","nviid":"2081"},
        {"id":"900","bolge":"AKDENİZ","il":"HATAY","ilce":"PAYAS","plaka":"31","nviid":"2083"},
        {"id":"901","bolge":"EGE","il":"MANİSA","ilce":"ŞEHZADELER","plaka":"45","nviid":"2086"},
        {"id":"902","bolge":"EGE","il":"MANİSA","ilce":"YUNUSEMRE","plaka":"45","nviid":"2087"},
        {"id":"903","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"DULKADİROĞLU","plaka":"46","nviid":"2084"},
        {"id":"904","bolge":"AKDENİZ","il":"KAHRAMANMARAŞ","ilce":"ONİKİŞUBAT","plaka":"46","nviid":"2085"},
        {"id":"905","bolge":"GÜNEYDOĞU ANADOLU","il":"MARDİN","ilce":"ARTUKLU","plaka":"47","nviid":"2088"},
        {"id":"906","bolge":"EGE","il":"MUĞLA","ilce":"MENTEŞE","plaka":"48","nviid":"2089"},
        {"id":"907","bolge":"EGE","il":"MUĞLA","ilce":"SEYDİKEMER","plaka":"48","nviid":"2090"},
        {"id":"908","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"SÜLEYMANPAŞA","plaka":"59","nviid":"2096"},
        {"id":"909","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"KAPAKLI","plaka":"59","nviid":"2095"},
        {"id":"910","bolge":"MARMARA","il":"TEKİRDAĞ","ilce":"ERGENE","plaka":"59","nviid":"2094"},
        {"id":"911","bolge":"KARADENİZ","il":"TRABZON","ilce":"ORTAHİSAR","plaka":"61","nviid":"2097"},
        {"id":"912","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"EYYÜBİYE","plaka":"63","nviid":"2091"},
        {"id":"913","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"HALİLİYE","plaka":"63","nviid":"2092"},
        {"id":"914","bolge":"GÜNEYDOĞU ANADOLU","il":"ŞANLIURFA","ilce":"KARAKÖPRÜ","plaka":"63","nviid":"2093"},
        {"id":"915","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"MERKEZ","plaka":"62","nviid":"1688"},
        {"id":"916","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"NAZIMİYE","plaka":"62","nviid":"1541"},
        {"id":"917","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"OVACIK","plaka":"62","nviid":"1562"},
        {"id":"918","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"PERTEK","plaka":"62","nviid":"1574"},
        {"id":"919","bolge":"DOĞU ANADOLU","il":"TUNCELİ","ilce":"PÜLÜMÜR","plaka":"62","nviid":"1581"},
        {"id":"920","bolge":"EGE","il":"UŞAK","ilce":"BANAZ","plaka":"64","nviid":"1170"},
        {"id":"921","bolge":"EGE","il":"UŞAK","ilce":"EŞME","plaka":"64","nviid":"1323"},
        {"id":"922","bolge":"EGE","il":"UŞAK","ilce":"KARAHALLI","plaka":"64","nviid":"1436"},
        {"id":"923","bolge":"EGE","il":"UŞAK","ilce":"MERKEZ","plaka":"64","nviid":"1704"},
        {"id":"924","bolge":"EGE","il":"UŞAK","ilce":"SİVASLI","plaka":"64","nviid":"1629"},
        {"id":"925","bolge":"EGE","il":"UŞAK","ilce":"ULUBEY","plaka":"64","nviid":"1697"},
        {"id":"926","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"BAHÇESARAY","plaka":"65","nviid":"1770"},
        {"id":"927","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"BAŞKALE","plaka":"65","nviid":"1175"},
        {"id":"928","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"ÇALDIRAN","plaka":"65","nviid":"1786"},
        {"id":"929","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"ÇATAK","plaka":"65","nviid":"1236"},
        {"id":"930","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"EDREMİT","plaka":"65","nviid":"1918"},
        {"id":"931","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"ERCİŞ","plaka":"65","nviid":"1309"},
        {"id":"932","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"GEVAŞ","plaka":"65","nviid":"1350"},
        {"id":"933","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"GÜRPINAR","plaka":"65","nviid":"1372"},
        {"id":"934","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"İPEKYOLU","plaka":"65","nviid":"2098"},
        {"id":"935","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"MURADİYE","plaka":"65","nviid":"1533"},
        {"id":"936","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"ÖZALP","plaka":"65","nviid":"1565"},
        {"id":"937","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"SARAY","plaka":"65","nviid":"1980"},
        {"id":"938","bolge":"DOĞU ANADOLU","il":"VAN","ilce":"TUŞBA","plaka":"65","nviid":"2099"},
        {"id":"939","bolge":"MARMARA","il":"YALOVA","ilce":"ALTINOVA","plaka":"77","nviid":"2019"},
        {"id":"940","bolge":"MARMARA","il":"YALOVA","ilce":"ARMUTLU","plaka":"77","nviid":"2020"},
        {"id":"941","bolge":"MARMARA","il":"YALOVA","ilce":"ÇINARCIK","plaka":"77","nviid":"2021"},
        {"id":"942","bolge":"MARMARA","il":"YALOVA","ilce":"ÇİFTLİKKÖY","plaka":"77","nviid":"2022"},
        {"id":"943","bolge":"MARMARA","il":"YALOVA","ilce":"MERKEZ","plaka":"77","nviid":"1716"},
        {"id":"944","bolge":"MARMARA","il":"YALOVA","ilce":"TERMAL","plaka":"77","nviid":"2026"},
        {"id":"945","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"AKDAĞMADENİ","plaka":"66","nviid":"1117"},
        {"id":"946","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"AYDINCIK","plaka":"66","nviid":"1877"},
        {"id":"947","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"BOĞAZLIYAN","plaka":"66","nviid":"1198"},
        {"id":"948","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"ÇANDIR","plaka":"66","nviid":"1895"},
        {"id":"949","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"ÇAYIRALAN","plaka":"66","nviid":"1242"},
        {"id":"950","bolge":"GÜNEYDOĞU ANADOLU","il":"ADIYAMAN","ilce":"BESNİ","plaka":"2","nviid":"1182"},
        {"id":"951","bolge":"EGE","il":"AFYONKARAHİSAR","ilce":"BAŞMAKÇI","plaka":"3","nviid":"1771"},
        {"id":"952","bolge":"DOĞU ANADOLU","il":"AĞRI","ilce":"MERKEZ","plaka":"4","nviid":"1111"},
        {"id":"953","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"ÇEKEREK","plaka":"66","nviid":"1245"},
        {"id":"954","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"KADIŞEHRİ","plaka":"66","nviid":"1952"},
        {"id":"955","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"MERKEZ","plaka":"66","nviid":"1733"},
        {"id":"956","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"SARAYKENT","plaka":"66","nviid":"1982"},
        {"id":"957","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"SARIKAYA","plaka":"66","nviid":"1602"},
        {"id":"958","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"SORGUN","plaka":"66","nviid":"1635"},
        {"id":"959","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"ŞEFAATLİ","plaka":"66","nviid":"1655"},
        {"id":"960","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"YENİFAKILI","plaka":"66","nviid":"1998"},
        {"id":"961","bolge":"İÇ ANADOLU","il":"YOZGAT","ilce":"YERKÖY","plaka":"66","nviid":"1726"},
        {"id":"962","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"ALAPLI","plaka":"67","nviid":"1758"},
        {"id":"963","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"ÇAYCUMA","plaka":"67","nviid":"1240"},
        {"id":"964","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"DEVREK","plaka":"67","nviid":"1276"},
        {"id":"965","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"EREĞLİ","plaka":"67","nviid":"1313"},
        {"id":"966","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"GÖKÇEBEY","plaka":"67","nviid":"1926"},
        {"id":"967","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"KİLİMLİ","plaka":"67","nviid":"2100"},
        {"id":"968","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"MERKEZ","plaka":"67","nviid":"1741"},
        {"id":"998","bolge":"KARADENİZ","il":"ZONGULDAK","ilce":"KOZLU","plaka":"67","nviid":"2101"},
        {"id":"999","bolge":"KARADENİZ","il":"ORDU","ilce":"ALTINORDU","plaka":"52","nviid":"2103"}
        ]
}

 export default data;