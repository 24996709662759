import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import axios from "axios";
import getCSRFToken from "./csrf";

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

declare global {
  interface Window {
    csrf_token_status: string;
  }
}

window.csrf_token_status = "unavailable";

function App() {
  const [isCSRFTokenReady, setIsCSRFTokenReady] = useState(false);

  useEffect(() => {
    const fetchCSRFToken = async () => {
      try {
        await getCSRFToken();
        setIsCSRFTokenReady(true);
      } catch (error) {
        console.error("CSRF token alınamadı:", error);
        // Burada kullanıcıya bir hata mesajı gösterebilirsiniz
      }
    };

    fetchCSRFToken();
  }, []);

  if (!isCSRFTokenReady) {
    return <div>Loading...</div>; // veya daha güzel bir yükleme ekranı
  }

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
